import { Component, Vue } from 'vue-property-decorator';
import Event from '@vertical-plus/vue-js-micro-service-components/lib/model/event/event';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import FooterComponent from '@/components/footer/index.vue';
import HeaderComponent from '@/components/header/index.vue';
import NavbarComponent from '@/components/navbar/index.vue';

@Component({
    components:
    {
        'app-footer': FooterComponent,
        'app-header': HeaderComponent,
        'app-navbar': NavbarComponent,
    },
})
export default class App extends Vue
{
    $refs!:
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        confirmDialog: any
    };

    private created()
    {
        this.unregisterServiceWorkers();

        this.initEvents();
    }

    /**
     * Unregisters any existing service workers
     */

    private async unregisterServiceWorkers()
    {
        if ('serviceWorker' in navigator)
        {
            const registrations = await navigator.serviceWorker.getRegistrations();

            for (const registration of registrations)
            {
                registration.unregister();
            }
        }
    }

    /**
     * Initialises events
     */

    private initEvents()
    {
        EventBus.instance.$on('confirm-dialog', (
            title: string, message: string, onConfirm: () => void, warning?: boolean,
        ) => this.handleConfirmDialogEvent(
            title, message, onConfirm, warning,
        ));

        EventBus.instance.$on(Event.SessionExpired, () => this.handleSessionExpired());
    }

    /**
     * Handles Confirm events
     */

    private async handleConfirmDialogEvent(
        title: string, message: string, onConfirm: () => void, warning = false,
    )
    {
        const isConfirmed = await this.$refs.confirmDialog.open(
            title, message, warning,
        );
        if (isConfirmed)
        {
            onConfirm();
        }
    }

    /**
     * Handles session expiry
     */

    private handleSessionExpired()
    {
        this.$serviceContainer.authenticationService.logout();
        this.$router.replace({ name: 'home' });
    }
}