export default class TaskHelper
{
    /**
     * Returns the id from an iri
     *
     * @param iri
     * @returns
     */

    public static getIdFromIri(iri: string): string
    {
        return iri.split('/').pop() ?? '';
    }

    /**
     * Returns an item from meta
     *
     * @param meta
     * @param iri
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static getItemFromMeta(meta: any, iri: string|unknown): unknown|null
    {
        if (iri)
        {
            if (typeof iri === 'string')
            {
                const id = TaskHelper.getIdFromIri(iri);

                return meta[id] ?? null;
            }
            else
            {
                return iri;
            }
        }

        return null;
    }
}