import { Method } from 'axios';
import AbstractTask from './abstract-task';

export default abstract class AbstractGetTask extends AbstractTask
{
    protected get method(): Method
    {
        return 'GET';
    }

    protected get successCode(): number
    {
        return 200;
    }
}