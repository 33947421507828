import AbstractGetTask from '../abstract-get-task';
import Exception from '@vertical-plus/vue-js-api-platform/dist/model/exception/exception';
import JobContribution from '@/model/entity/job/job-contribution';
import JobContributionDto from '@/model/entity/job/job-contribution-dto';
import JobContributionSearchConditionsInterface from '@/model/vo/job/job-contribution-search-conditions-interface';
import JobContributionSearchResultsVo from '@/model/vo/job/job-contribution-search-results-vo';
import TaskParamsInterface from '@vertical-plus/vue-js-api-platform/dist/model/task/task-params-interface';

export default class GetJobContributionsTask extends AbstractGetTask
{
    public searchConditions?: JobContributionSearchConditionsInterface;

    public async run(): Promise<JobContributionSearchResultsVo>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            const searchResults = this.parseSearchResults(response);

            const jobContributions: JobContribution[] = [];

            for (const jobContributionDto of searchResults.results)
            {
                const dto = jobContributionDto as JobContributionDto;

                jobContributions.push(new JobContribution(dto));
            }

            searchResults.results = jobContributions;

            return searchResults as JobContributionSearchResultsVo;
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return '/job_contributions';
    }

    protected get params(): TaskParamsInterface|null
    {
        if (!this.searchConditions)
        {
            return null;
        }

        return this.generateParamsFromData(this.searchConditions);
    }
}