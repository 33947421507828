import AbstractPatchTask from '../abstract-patch-task';
import ClientUser from '@/model/entity/client/client-user';

export default class PatchClientUserTask extends AbstractPatchTask
{
    public clientUser!: ClientUser;

    public async run(): Promise<ClientUser|null|void>
    {
        const response = await this.execute();

        if (response.status === this.successCode)
        {
            return new ClientUser(response.data);
        }

        this.handleErrorResponse(response.status);
    }

    protected get url(): string
    {
        return `/client_users/${ this.clientUser.id }`;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}
    {
        return this.clientUser.params;
    }
}