import { find } from 'lodash';
import AbstractAuthenticatedService from '../abstract-authenticated-service';
import AttachmentInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/attachment-interface';
import CloseJobTask from '@/model/task/job/close-job-task';
import DownloadAttachmentTask from '@/model/task/job/download-attachment-task';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import FormFieldInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/form-field-interface';
import FormFieldOptionInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/form-field-option-interface';
import GetJobCategoriesTask from '@/model/task/job/get-job-categories-task';
import GetJobContributionsTask from '@/model/task/job/get-job-contributions-task';
import GetJobCountsTask from '@/model/task/job/get-job-counts-task';
import GetJobStagesTask from '@/model/task/job/get-job-stages-task';
import GetJobTask from '@/model/task/job/get-job-task';
import GetJobsTask from '@/model/task/job/get-jobs-task';
import Job from '@/model/entity/job/job';
import JobCategory from '@/model/entity/job/job-category';
import JobCategorySearchConditionsInterface from '@/model/vo/job/job-category-search-conditions-interface';
import JobCategorySearchResultsVo from '@/model/vo/job/job-category-search-results-vo';
import JobContribution from '@/model/entity/job/job-contribution';
import JobContributionSearchConditionsInterface from '@/model/vo/job/job-contribution-search-conditions-interface';
import JobContributionSearchResultsVo from '@/model/vo/job/job-contribution-search-results-vo';
import JobCountsInterface from '@/model/vo/job/job-counts-interface';
import JobSearchConditionsInterface from '@/model/vo/job/job-search-conditions-interface';
import JobSearchResultsVo from '@/model/vo/job/job-search-results-vo';
import JobStageSearchConditionsInterface from '@/model/vo/job/job-stage-search-conditions-interface';
import JobStageSearchResultsVo from '@/model/vo/job/job-stage-search-results-vo';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import PatchJobContributionTask from '@/model/task/job/patch-job-contribution-task';
import PatchJobTask from '@/model/task/job/patch-job-task';
import PostJobContributionTask from '@/model/task/job/post-job-contribution-task';
import PostJobTask from '@/model/task/job/post-job-task';

export default class JobService extends AbstractAuthenticatedService
{
    public static STAGE_ACCEPTANCE_TESTING: number = parseInt(process.env.VUE_APP_JOB_STAGE_ACCEPTANCE_TESTING);
    public static STAGE_DEFERRED: number = parseInt(process.env.VUE_APP_JOB_STAGE_DEFERRED);
    public static STAGE_IN_PROGRESS: number = parseInt(process.env.VUE_APP_JOB_STAGE_IN_PROGRESS);
    public static STAGE_JOB_ASSIGNED: number = parseInt(process.env.VUE_APP_JOB_STAGE_JOB_ASSIGNED);
    public static STAGE_QUALITY_CONTROL: number = parseInt(process.env.VUE_APP_JOB_STAGE_QUALITY_CONTROL);
    public static STAGE_RECURRING: number = parseInt(process.env.VUE_APP_JOB_STAGE_RECURRING);
    public static STAGE_STANDARD_SUPPORT: number = parseInt(process.env.VUE_APP_JOB_STAGE_STANDARD_SUPPORT);
    public static STAGE_WAITING_FOR_CLIENT: number = parseInt(process.env.VUE_APP_JOB_STAGE_WAITING_FOR_CLIENT);

    public static TYPE_MEETING = process.env.VUE_APP_TECHSPEC_TYPE_MEETING;

    public static levelOptions: FormFieldInterface['options'] = [
        {
            value: 'low',
            title: 'Low',
        },
        {
            value: 'medium',
            title: 'Medium',
        },
        {
            value: 'high',
            title: 'High',
        },
    ];

    public static priorityOptions: FormFieldInterface['options'] = [
        {
            value: 1,
            title: 'Urgent',
        },
        {
            value: 2,
            title: 'High',
        },
        {
            value: 3,
            title: 'Normal',
        },
        {
            value: 4,
            title: 'Low',
        },
        {
            value: 5,
            title: 'Very Low',
        },
    ];

    public static reasonOptions: FormFieldInterface['options'] = [
        {
            value: 'customer_satisfaction',
            title: 'Customer Satisfaction',
        },
        {
            value: 'productivity_improvement',
            title: 'Productivity Improvement',
        },
        {
            value: 'sales_increase',
            title: 'Sales Increase',
        },
    ];

    public static ticketOptions: FormFieldInterface['options'] = [
        {
            value: null,
            title: 'No',
        },
        {
            value: 'emergency_same_day',
            title: '1 day',
        },
        {
            value: 'emergency_seven_days',
            title: '7 days',
        },
    ];

    public static typeOptions: FormFieldInterface['options'] = [
        {
            value: 'Client Added',
            title: 'Client Added',
        },
        {
            value: 'content',
            title: 'Content',
        },
        {
            value: 'development',
            title: 'Development',
        },
        {
            value: 'enquiry',
            title: 'Enquiry',
        },
        {
            value: 'Meeting Job',
            title: 'Meeting Job',
        },
        {
            value: 'projects',
            title: 'Projects',
        },
        {
            value: 'reports',
            title: 'Reports',
        },
        {
            value: 'sales',
            title: 'Sales',
        },
        {
            value: 'support',
            title: 'Support',
        },
        {
            value: 'technical',
            title: 'Technical',
        },
        {
            value: 'technical support',
            title: 'Technical Support',
        },
    ];

    private cachedJobCategories: JobCategory[]|null = null;

    /**
     * Returns the title for a priority value
     *
     * @param value
     * @returns
     */

    public static getPriorityTitle(value?: number): string
    {
        if (!value)
        {
            return '-';
        }

        const priority = find(JobService.priorityOptions, option => option.value === value);
        if (!priority)
        {
            return '-';
        }

        return priority.title;
    }

    /**
     * Returns the title for a type value
     *
     * @param value
     * @returns
     */

    public static getTypeTitle(value?: string): string
    {
        if (!value)
        {
            return '-';
        }

        const type = find(JobService.typeOptions, option => option.value === value);
        if (!type)
        {
            return '-';
        }

        return type.title;
    }

    /**
     * Returns matching jobs
     *
     * @returns
     */

    public async findJobs(searchConditions?: JobSearchConditionsInterface): Promise<JobSearchResultsVo>
    {
        const task = new GetJobsTask();
        task.token = this.token;
        task.searchConditions = searchConditions;

        return this.runTask(task) as Promise<JobSearchResultsVo>;
    }

    /**
     * Loads a job
     *
     * @param id
     * @returns
     */

    public async loadJob(id: number|string): Promise<Job|null>
    {
        if (typeof id === 'string')
        {
            id = parseInt(id);
            if (isNaN(id))
            {
                return null;
            }
        }

        const task = new GetJobTask();
        task.token = this.token;
        task.id = id;

        return this.runTask(task) as Promise<Job>;
    }

    /**
     * Creates a job
     *
     * @param job
     * @returns
     */

    public async createJob(job: Job): Promise<Job>
    {
        const task = new PostJobTask();
        task.token = this.token;
        task.job = job;

        return this.runTask(task) as Promise<Job>;
    }

    /**
     * Updates a job
     *
     * @param job
     * @returns
     */

    public async updateJob(job: Job): Promise<Job>
    {
        const task = new PatchJobTask();
        task.token = this.token;
        task.job = job;

        return this.runTask(task) as Promise<Job>;
    }

    /**
     * Closes a job
     *
     * @param jobId
     * @returns
     */

    public async closeJob(jobId: number): Promise<Job>
    {
        const task = new CloseJobTask();
        task.token = this.token;
        task.jobId = jobId;

        return this.runTask(task) as Promise<Job>;
    }

    /**
     * Downloads an attachment
     *
     * @param attachment
     */

    public async downloadAttachment(attachment: AttachmentInterface): Promise<void>
    {
        if (!attachment.uri)
        {
            return;
        }

        const task = new DownloadAttachmentTask();
        task.token = this.token;
        task.attachment = attachment;

        try
        {
            return await this.runTask(task) as Promise<void>;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any)
        {
            EventBus.instance.$emit(
                'notification',
                LocaleHelper.getI18n().t('job.error.failed_downloading_attachment'),
                'error',
            );
        }
    }

    /**
     * Callback function for form options
     *
     * @param search
     * @param valueAsIri
     * @returns
     */

    public async jobFormOptionsCallback(search: string, valueAsIri = false): Promise<FormFieldOptionInterface[]>
    {
        const jobs = await this.findJobs({
            searchTerm: search,
            order: [
                {
                    key: 'name',
                    descending: false,
                },
            ],
        });

        const options: FormFieldOptionInterface[] = [];

        for (const job of jobs.results)
        {
            options.push({
                value: valueAsIri ? `/jobs/${ job.id }` : job.id,
                title: job.name,
            });
        }

        return options;
    }

    /**
     * Callback function for form titles
     *
     * @param values
     * @param valueAsIri
     * @returns
     */

    public async jobFormTitlesCallback(values: string[], valueAsIri = false): Promise<Record<string, string>>
    {
        const jobs = await this.findJobs({
            id: values,
            order: [
                {
                    key: 'name',
                    descending: false,
                },
            ],
        });

        const options: Record<string, string> = {};

        for (const job of jobs.results)
        {
            if (!job.id)
            {
                continue;
            }

            if (valueAsIri)
            {
                options[`/jobs/${ job.id }`] = job.name;
            }
            else
            {
                options[job.id] = job.name;
            }
        }

        return options;
    }

    /**
     * Returns matching job categories
     *
     * @returns
     */

    public async findJobCategories(searchConditions?: JobCategorySearchConditionsInterface): Promise<JobCategorySearchResultsVo>
    {
        const task = new GetJobCategoriesTask();
        task.token = this.token;
        task.searchConditions = searchConditions;

        return this.runTask(task) as Promise<JobCategorySearchResultsVo>;
    }

    /**
     * Callback function for form options
     *
     * @param search
     * @returns
     */

    public async jobCategoryFormOptionsCallback(search: string): Promise<FormFieldOptionInterface[]>
    {
        if (!this.cachedJobCategories)
        {
            const categories = await this.findJobCategories({
                order: [
                    {
                        key: 'displayOrder',
                        descending: false,
                    },
                    {
                        key: 'name',
                        descending: false,
                    },
                ],
            });

            this.cachedJobCategories = categories.results;
        }

        const options: FormFieldOptionInterface[] = [];

        for (const category of this.cachedJobCategories)
        {
            if (!category.name.toLowerCase().includes(search.toLowerCase()))
            {
                continue;
            }

            options.push({
                value: category.id,
                title: category.name,
            });
        }

        return options;
    }

    /**
     * Callback function for form titles
     *
     * @param values
     * @returns
     */

    public async jobCategoryFormTitlesCallback(values: string[]): Promise<Record<string, string>>
    {
        if (!this.cachedJobCategories)
        {
            const categories = await this.findJobCategories({
                order: [
                    {
                        key: 'displayOrder',
                        descending: false,
                    },
                    {
                        key: 'name',
                        descending: false,
                    },
                ],
            });

            this.cachedJobCategories = categories.results;
        }

        const options: Record<string, string> = {};

        for (const category of this.cachedJobCategories)
        {
            if (!category.id || !values.includes(category.id.toString()))
            {
                continue;
            }

            options[category.id] = category.name;
        }

        return options;
    }

    /**
     * Returns matching job contributions
     *
     * @returns
     */

    public async findJobContributions(searchConditions?: JobContributionSearchConditionsInterface): Promise<JobContributionSearchResultsVo>
    {
        const task = new GetJobContributionsTask();
        task.token = this.token;
        task.searchConditions = searchConditions;

        return this.runTask(task) as Promise<JobContributionSearchResultsVo>;
    }

    /**
     * Creates a job contribution
     *
     * @param jobContribution
     * @returns
     */

    public async createJobContribution(jobContribution: JobContribution): Promise<Job>
    {
        const task = new PostJobContributionTask();
        task.token = this.token;
        task.jobContribution = jobContribution;

        return this.runTask(task) as Promise<Job>;
    }

    /**
     * Updates a job contribution
     *
     * @param jobContribution
     * @returns
     */

    public async updateJobContribution(jobContribution: JobContribution): Promise<Job>
    {
        const task = new PatchJobContributionTask();
        task.token = this.token;
        task.jobContribution = jobContribution;

        return this.runTask(task) as Promise<Job>;
    }

    /**
     * Returns matching job stages
     *
     * @returns
     */

    public async findJobStages(searchConditions?: JobStageSearchConditionsInterface): Promise<JobStageSearchResultsVo>
    {
        const task = new GetJobStagesTask();
        task.token = this.token;
        task.searchConditions = searchConditions;

        return this.runTask(task) as Promise<JobStageSearchResultsVo>;
    }

    /**
     * Callback function for form options
     *
     * @param search
     * @returns
     */

    public async jobStageFormOptionsCallback(search: string): Promise<FormFieldOptionInterface[]>
    {
        const stages = await this.findJobStages({
            searchTerm: search,
            deleted: false,
            order: [
                {
                    key: 'stage',
                    descending: false,
                },
                {
                    key: 'displayOrder',
                    descending: false,
                },
                {
                    key: 'name',
                    descending: false,
                },
            ],
        });

        const options: FormFieldOptionInterface[] = [];

        for (const stage of stages.results)
        {
            options.push({
                value: stage.id,
                title: stage.name,
            });
        }

        return options;
    }

    /**
     * Callback function for form titles
     *
     * @param values
     * @returns
     */

    public async jobStageFormTitlesCallback(values: string[]): Promise<Record<string, string>>
    {
        const stages = await this.findJobStages({
            id: values,
            order: [
                {
                    key: 'stage',
                    descending: false,
                },
                {
                    key: 'displayOrder',
                    descending: false,
                },
                {
                    key: 'name',
                    descending: false,
                },
            ],
        });

        const options: Record<string, string> = {};

        for (const stage of stages.results)
        {
            if (!stage.id)
            {
                continue;
            }

            options[stage.id] = stage.name;
        }

        return options;
    }

    /**
     * Loads the job counts
     *
     * @returns
     */

    public async loadJobCounts(): Promise<JobCountsInterface>
    {
        const task = new GetJobCountsTask();
        task.token = this.token;

        return this.runTask(task) as Promise<JobCountsInterface>;
    }
}