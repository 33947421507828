import AttachmentInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/attachment-interface';
import ClientUserDto from '../client/client-user-dto';
import JobCategoryDto from './job-category-dto';
import JobStageDto from './job-stage-dto';
import UserDto from '@vertical-plus/vue-js-micro-service-components/lib/model/entity/organisation/user-dto';

export default class JobDto
{
    public id: number|null = null;
    public clientUser: ClientUserDto|null = null;
    public creator: UserDto|null = null;
    public assignee: UserDto|null = null;
    public assignedDate: string|null = null;
    public manager: UserDto|null = null;
    public name = '';
    public description: string|null = null;
    public priority = 3;
    public stage: JobStageDto|null = null;
    public stageEntryDate = '';
    public attachment1: AttachmentInterface|null = null;
    public attachment2: AttachmentInterface|null = null;
    public attachment3: AttachmentInterface|null = null;
    public attachment4: AttachmentInterface|null = null;
    public attachment5: AttachmentInterface|null = null;
    public category: JobCategoryDto|null = null;
    public tags: string[] = [];
    public dateCreated = '';
    public dateUpdated = '';
}