import Vue, { PluginObject } from 'vue';

import { Location } from 'vue-router';
import { setMilliseconds, setSeconds } from 'date-fns';
import ClientUser from '@/model/entity/client/client-user';
import Job from '@/model/entity/job/job';
import ServiceContainer from '@/model/service/service-container';
import showdown from 'showdown';
import store from '@/store';

// Instantiate a single markdown converter
const converter = new showdown.Converter();
converter.setFlavor('github');

// Instantiate a single service container
const serviceContainer = new ServiceContainer(store);

declare module 'vue/types/vue'
{
    interface Vue
    {
        $serviceContainer: ServiceContainer;

        $clearSeconds(date: Date): Date;
        $clientUser: ClientUser|null;
        $jobRoute(job: Job, edit: boolean): Location|null;
        $markdownToHtml(markdown: string): string;
        $lockPage(): void;
        $titleCase(value: string): string;
        $unlockPage(): void;
    }
}

const VerticalPlusPlugin: PluginObject<Vue> =
{
    install(Vue)
    {
        // Service container
        Vue.prototype.$serviceContainer = serviceContainer;

        Object.defineProperties(Vue.prototype, {
            $clientUser:
            {
                get(): ClientUser|null
                {
                    return this.$store.getters['app/clientUser'];
                },
            },
            $showTemplate:
            {
                get(): boolean
                {
                    if (this.$isEmbedded)
                    {
                        return false;
                    }

                    if (!this.$clientUser)
                    {
                        return false;
                    }

                    return true;
                },
            },
        });

        // Routes
        Vue.prototype.$jobRoute = (job: Job|null, edit = false) =>
        {
            if (!job?.id)
            {
                return null;
            }

            return {
                name: edit ? 'job-edit' : 'job-view',
                params: {
                    id: job.id.toString(),
                },
            };
        };

        // Markdown -> HTML converter
        Vue.prototype.$markdownToHtml = (markdown: string) =>
        {
            const converter = new showdown.Converter();
            converter.setFlavor('github');

            return converter.makeHtml(markdown);
        };

        // Titlecase
        Vue.prototype.$titleCase = (value: string) => value.replace(/\w\S*/g,
            value => value.charAt(0).toUpperCase() + value.substring(1).toLowerCase());

        // Page locking

        Vue.prototype.$lockPage = () =>
        {
            store.commit('ui/pageLocked', true);
        };

        Vue.prototype.$unlockPage = () =>
        {
            store.commit('ui/pageLocked', false);
        };

        // Clears the seconds (and milliseconds) from a date
        Vue.prototype.$clearSeconds = (date: Date) => setSeconds(setMilliseconds(date, 0), 0);

        // Markdown converter
        Vue.prototype.$markdownToHtml = (markdown: string) => converter.makeHtml(markdown);
    },
};

Vue.use(VerticalPlusPlugin);

export default VerticalPlusPlugin;