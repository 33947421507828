import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import ClientUser from '@/model/entity/client/client-user';
import ClientUserDto from '@/model/entity/client/client-user-dto';
import FormFieldInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/form-field-interface';
import FormFieldSetInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/form-field-set-interface';
import FormInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/form-interface';

@Component
export default class ProfileForm extends Vue
{
    @Prop({
        type: Boolean,
        default: false,
    })
    private saving!: boolean;

    private form: FormInterface|null = null;

    private created()
    {
        this.onUserChanged();
    }

    @Watch('$clientUser')
    private onUserChanged()
    {
        this.generateForm();
    }

    /**
     * Handles form saves
     *
     * @param form
     */

    private async onSave(form: FormInterface)
    {
        if (!this.$clientUser)
        {
            return;
        }

        // Generate a user from the form data
        const clientUser = this.generateUser(form);

        // Emit a save event with the user
        this.$emit('save', clientUser);
    }

    /**
     * Generates the form
     */

    private generateForm()
    {
        if (!this.$clientUser)
        {
            this.form = null;
            return;
        }

        const form: FormInterface =
        {
            items: [
                {
                    key: 'title',
                    type: 'string',
                    title: this.$t('authentication.field.title').toString(),
                    value: this.$clientUser.title,
                    required: true,
                },
                {
                    key: 'firstName',
                    type: 'string',
                    title: this.$t('authentication.field.first_name').toString(),
                    value: this.$clientUser.firstName,
                    required: true,
                },
                {
                    key: 'lastName',
                    type: 'string',
                    title: this.$t('authentication.field.last_name').toString(),
                    value: this.$clientUser.lastName,
                    required: true,
                },
                {
                    key: 'email',
                    type: 'string',
                    title: this.$t('authentication.field.email').toString(),
                    value: this.$clientUser.email,
                    required: true,
                },
                {
                    key: 'username',
                    type: 'string',
                    title: this.$t('authentication.field.username').toString(),
                    value: this.$clientUser.username,
                    required: true,
                    readonly: true,
                },
                {
                    key: 'password',
                    type: 'string',
                    title: this.$t('authentication.field.password').toString(),
                    value: null,
                    required: false,
                    discreet: true,
                    discreetToggle: true,
                    validator: (field, fieldValues) =>
                    {
                        const errors: string[] = [];

                        if (field.value && (field.value as string).length > 0 && (field.value as string).length < 8)
                        {
                            errors.push(this.$t('authentication.error.password_must_be_characters', { length: 8 }).toString());
                        }

                        return errors;
                    },
                },
                {
                    key: 'passwordConfirmation',
                    type: 'string',
                    title: this.$t('authentication.field.password_confirmation').toString(),
                    value: null,
                    required: false,
                    discreet: true,
                    discreetToggle: true,
                    dependencies: {
                        password: /.+/,
                    },
                    validator: (field, fieldValues) =>
                    {
                        const errors: string[] = [];

                        if (field.value !== fieldValues.password)
                        {
                            errors.push(this.$t('authentication.error.passwords_must_match').toString());
                        }

                        return errors;
                    },
                },
            ],
        };

        this.form = Object.assign({}, form);
    }

    /**
     * Generates the user from the form
     */

    private generateUser(form: FormInterface): ClientUser
    {
        if (!this.$clientUser)
        {
            return new ClientUser(new ClientUserDto());
        }

        const clientUser = new ClientUser(cloneDeep(this.$clientUser.dto));

        this.addFieldValues(clientUser, form.items);

        return clientUser;
    }

    /**
     * Adds field values from form items to the passed user
     *
     * @param clientUser
     * @param items
     */

    private addFieldValues(clientUser: ClientUser, items: (FormFieldSetInterface | FormFieldInterface)[])
    {
        for (const item of items)
        {
            if (!('key' in item))
            {
                this.addFieldValues(clientUser, item.fields);
                continue;
            }

            switch (item.key)
            {
            case 'title':
                clientUser.title = item.value as string;
                break;

            case 'firstName':
                clientUser.firstName = item.value as string;
                break;

            case 'lastName':
                clientUser.lastName = item.value as string;
                break;

            case 'email':
                clientUser.email = item.value as string;
                break;

            case 'password':
                clientUser.password = item.value as string;
                break;
            }
        }
    }
}