import { Component, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import AbstractView from '@/views/abstract-view';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import Job from '@/model/entity/job/job';
import JobInformation from '@/components/job-information/index.vue';
import JobService from '@/model/service/job/job-service';

@Component({
    components: {
        'app-job-information': JobInformation,
    },
})
export default class JobView extends AbstractView
{
    private job: Job|null = null;
    private options = false;

    protected get title(): string
    {
        return this.job ? `${ this.job.name } (${ this.job.id })` : this.defaultTitle;
    }

    protected created()
    {
        this.loadJob();
    }

    @Watch('$route')
    private onRouteChanged()
    {
        this.loadJob();
    }

    /**
     * Returns whether the job can be closed by the client
     */

    private get canBeClosed(): boolean
    {
        const stageId = this.job?.stage?.id;
        if (!stageId)
        {
            return false;
        }

        switch (stageId)
        {
        case JobService.STAGE_WAITING_FOR_CLIENT:
        case JobService.STAGE_ACCEPTANCE_TESTING:
            return true;

        default:
            return false;
        }
    }

    /**
     * Loads the job
     */

    private async loadJob()
    {
        try
        {
            this.job = await this.$serviceContainer.jobService.loadJob(this.$route.params.id);
        }
        catch (error: unknown)
        {
            EventBus.instance.$emit(
                'notification',
                this.$t('general.error.failed_loading_server_error'),
                'error',
            );
        }
    }

    /**
     * Handles close job requests
     */

    private onCloseJob()
    {
        EventBus.instance.$emit(
            'confirm-dialog',
            this.$t('job.message.close_job_confirmation_title'),
            this.$t('job.message.close_job_confirmation_body'),
            () =>
            {
                this.closeJob();
            },
        );
    }

    /**
     * Closes the job
     */

    private async closeJob()
    {
        if (!this.job?.id)
        {
            return;
        }

        try
        {
            this.job = await this.$serviceContainer.jobService.closeJob(this.job.id);

            EventBus.instance.$emit('reload-job-counts');

            EventBus.instance.$emit(
                'notification',
                this.$t('job.message.closed_successfully'),
                'success',
            );
        }
        catch (error: unknown)
        {
            EventBus.instance.$emit(
                'notification',
                this.$t('job.error.failed_closing_job'),
                'error',
            );
        }
    }
}