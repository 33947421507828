import { Component } from 'vue-property-decorator';
import AbstractView from '@/views/abstract-view';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import Job from '@/model/entity/job/job';
import JobDto from '@/model/entity/job/job-dto';
import JobForm from '@/components/job-form/index.vue';

@Component({
    components:
    {
        'app-job-form': JobForm,
    },
})
export default class JobCreateView extends AbstractView
{
    private job: Job|null = null;
    private saving = false;

    protected get title(): string
    {
        return this.$t('job.message.title_new_job_request').toString();
    }

    protected created()
    {
        this.job = new Job(new JobDto());
    }

    /**
     * Handles form saves
     *
     * @param job
     */

    private async onSave(job: Job)
    {
        try
        {
            this.saving = true;

            const created = await this.$serviceContainer.jobService.createJob(job);

            EventBus.instance.$emit('reload-job-counts');

            EventBus.instance.$emit(
                'notification',
                this.$t('job.message.job_requested_submitted'),
                'success',
            );

            this.$router.replace({
                name: 'job-view',
                params: {
                    id: `${ created.id }`,
                },
            }).catch();
        }
        catch (error: unknown)
        {
            EventBus.instance.$emit(
                'notification',
                this.$t('general.error.failed_saving_server_error'),
                'error',
            );
        }
        finally
        {
            this.saving = false;
        }
    }
}