import AbstractPatchTask from '../abstract-patch-task';
import Job from '@/model/entity/job/job';

export default class PatchJobTask extends AbstractPatchTask
{
    public job!: Job;

    public async run(): Promise<Job|null|void>
    {
        const response = await this.execute();

        if (response.status === this.successCode)
        {
            return new Job(response.data);
        }

        this.handleErrorResponse(response.status);
    }

    protected get url(): string
    {
        return `/jobs/${ this.job.id }`;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}
    {
        return this.job.params;
    }
}