import { messages as componentMessages } from '@vertical-plus/vue-js-components';
import { messages as microServiceComponentMessages } from '@vertical-plus/vue-js-micro-service-components';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import appMessages from './translations/messages';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {},
});

i18n.mergeLocaleMessage('en', componentMessages.en);
i18n.mergeLocaleMessage('en', microServiceComponentMessages.en);
i18n.mergeLocaleMessage('en', appMessages.en);

LocaleHelper.setI18n(i18n);
LocaleHelper.setSupportedLocales([ 'en' ]);

export default i18n;