import { Component } from 'vue-property-decorator';
import AbstractView from '@/views/abstract-view';
import ClientUser from '@/model/entity/client/client-user';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import ProfileForm from '@/components/profile-form/index.vue';

@Component({
    components:
    {
        'app-profile-form': ProfileForm,
    },
})
export default class ProfileView extends AbstractView
{
    private saving = false;

    protected get title(): string
    {
        return this.$t('navbar.message.my_account').toString();
    }

    /**
     * Handles form saves
     *
     * @param clientUser
     */

    private async onSave(clientUser: ClientUser)
    {
        try
        {
            this.saving = true;

            await this.$serviceContainer.partnerService.updateAccount(clientUser);

            EventBus.instance.$emit(
                'notification',
                this.$t('authentication.message.account_details_updated'),
                'success',
            );
        }
        catch (error: unknown)
        {
            EventBus.instance.$emit(
                'notification',
                this.$t('general.error.failed_saving_server_error'),
                'error',
            );
        }
        finally
        {
            this.saving = false;
        }
    }
}