import AbstractAuthenticatedService from '../abstract-authenticated-service';
import ClientUser from '@/model/entity/client/client-user';
import PatchClientUserTask from '@/model/task/client/patch-client-user-task';
import ResetPasswordTask from '@/model/task/authentication/reset-password';

export default class PartnerService extends AbstractAuthenticatedService
{
    /**
     * Resets the password for the user with the given email address
     *
     * @param email
     */

    public async resetPassword(email: string)
    {
        const task = new ResetPasswordTask();
        task.email = email;

        return task.run();
    }

    /**
     * Updates the user's account details
     *
     * @param user
     */

    public async updateAccount(user: ClientUser): Promise<void>
    {
        const task = new PatchClientUserTask();
        task.token = this.token;
        task.clientUser = user;

        const updatedUser = await this.runTask(task) as Promise<ClientUser|null>;

        // Update the store with the user
        if (updatedUser)
        {
            this.store.commit('app/clientUser', updatedUser);
        }
    }
}