import { Component, Vue, Watch } from 'vue-property-decorator';
import JobDataTableReport from '@/model/service/job/report/job-data-table-report';
import JobService from '@/model/service/job/job-service';

@Component
export default class JobIndex extends Vue
{
    $refs!:
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataTable: any,
    };

    protected report: JobDataTableReport|null = null;

    private created()
    {
        this.onClientUserChanged();
    }

    @Watch('$clientUser')
    private onClientUserChanged()
    {
        if (!this.$clientUser)
        {
            this.report = null;
            return;
        }

        this.report = new JobDataTableReport(
            this.$serviceContainer,
            this.$clientUser,
            this.$route.params.type,
        );
    }

    @Watch('$route.params.type')
    private onTypeChanged()
    {
        if (!this.report)
        {
            return;
        }

        this.report.setType(this.$route.params.type);
        this.reload();
    }

    protected get title(): string
    {
        switch (this.$route.params.type)
        {
        case 'current':
            return this.$t('job.message.title_jobs_current').toString();

        case 'pending':
            return this.$t('job.message.title_jobs_pending').toString();

        case 'awaiting-input':
            return this.$t('job.message.title_jobs_awaiting_input').toString();

        case 'awaiting-approval':
            return this.$t('job.message.title_jobs_awaiting_approval').toString();

        case 'complete':
            return this.$t('job.message.title_jobs_completed').toString();

        case 'search':
            return this.$t('general.message.search_results').toString();

        default:
            return this.$t('navbar.message.jobs').toString();
        }
    }

    /**
     * Reloads the content
     */

    public reload()
    {
        this.$refs.dataTable.reload();
    }

    /**
     * Returns the priority title for the value
     *
     * @param value
     * @returns
     */

    private getPriorityTitle(value: number): string
    {
        return JobService.getPriorityTitle(value);
    }
}