import { cloneDeep } from 'lodash';
import { formatISO, parseISO } from 'date-fns';
import AttachmentInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/attachment-interface';
import ClientUser from '../client/client-user';
import JobCategory from './job-category';
import JobDto from './job-dto';
import JobStage from './job-stage';
import User from '@vertical-plus/vue-js-micro-service-components/lib/model/entity/organisation/user';

export default class Job
{
    public dto!: JobDto;

    public constructor(dto: JobDto)
    {
        this.dto = Object.assign({}, dto);

        this._clientUserId = this.dto.clientUser ? this.dto.clientUser.id : null;
        this._creatorId = this.dto.creator ? this.dto.creator.id : null;
        this._assigneeId = this.dto.assignee ? this.dto.assignee.id : null;
        this._managerId = this.dto.manager ? this.dto.manager.id : null;
        this._stageId = this.dto.stage ? this.dto.stage.id : null;
        this._categoryId = this.dto.category ? this.dto.category.id : null;
    }

    public get id(): number|null
    {
        return this.dto.id;
    }

    public set id(id: number|null)
    {
        this.dto.id = id;
    }

    public get clientUser(): ClientUser|null
    {
        if (!this.dto.clientUser)
        {
            return null;
        }

        return new ClientUser(this.dto.clientUser);
    }

    private _clientUserId: number|null = null;

    public get clientUserId(): number|null
    {
        return this._clientUserId;
    }

    public set clientUserId(id: number|null)
    {
        this._clientUserId = id;
    }

    public get creator(): User|null
    {
        if (!this.dto.creator)
        {
            return null;
        }

        return new User(this.dto.creator);
    }

    private _creatorId: string|null = null;

    public get creatorId(): string|null
    {
        return this._creatorId;
    }

    public set creatorId(id: string|null)
    {
        this._creatorId = id;
    }

    public get assignee(): User|null
    {
        if (!this.dto.assignee)
        {
            return null;
        }

        return new User(this.dto.assignee);
    }

    private _assigneeId: string|null = null;

    public get assigneeId(): string|null
    {
        return this._assigneeId;
    }

    public set assigneeId(id: string|null)
    {
        this._assigneeId = id;
    }

    public get assignedDate(): Date|null
    {
        if (!this.dto.assignedDate)
        {
            return null;
        }

        return parseISO(this.dto.assignedDate);
    }

    public set assignedDate(assignedDate: Date|null)
    {
        if (!assignedDate)
        {
            this.dto.assignedDate = null;
            return;
        }

        this.dto.assignedDate = formatISO(assignedDate);
    }

    public get manager(): User|null
    {
        if (!this.dto.manager)
        {
            return null;
        }

        return new User(this.dto.manager);
    }

    private _managerId: string|null = null;

    public get managerId(): string|null
    {
        return this._managerId;
    }

    public set managerId(id: string|null)
    {
        this._managerId = id;
    }

    public get name(): string
    {
        return this.dto.name;
    }

    public set name(name: string)
    {
        this.dto.name = name;
    }

    public get description(): string|null
    {
        return this.dto.description;
    }

    public set description(description: string|null)
    {
        this.dto.description = description;
    }

    public get priority(): number
    {
        return this.dto.priority;
    }

    public set priority(priority: number)
    {
        this.dto.priority = priority;
    }

    public get stage(): JobStage|null
    {
        if (!this.dto.stage)
        {
            return null;
        }

        return new JobStage(this.dto.stage);
    }

    private _stageId: number|null = null;

    public get stageId(): number|null
    {
        return this._stageId;
    }

    public set stageId(id: number|null)
    {
        this._stageId = id;
    }

    public get stageEntryDate(): Date
    {
        return parseISO(this.dto.stageEntryDate);
    }

    public set stageEntryDate(stageEntryDate: Date)
    {
        this.dto.stageEntryDate = formatISO(stageEntryDate);
    }

    public get hasAttachments(): boolean
    {
        return !!(this.attachment1 || this.attachment2 || this.attachment3 || this.attachment4 || this.attachment5);
    }

    public get attachment1(): AttachmentInterface|null
    {
        return this.dto.attachment1;
    }

    public set attachment1(attachment1: AttachmentInterface|null)
    {
        this.dto.attachment1 = attachment1;
    }

    public get attachment2(): AttachmentInterface|null
    {
        return this.dto.attachment2;
    }

    public set attachment2(attachment2: AttachmentInterface|null)
    {
        this.dto.attachment2 = attachment2;
    }

    public get attachment3(): AttachmentInterface|null
    {
        return this.dto.attachment3;
    }

    public set attachment3(attachment3: AttachmentInterface|null)
    {
        this.dto.attachment3 = attachment3;
    }

    public get attachment4(): AttachmentInterface|null
    {
        return this.dto.attachment4;
    }

    public set attachment4(attachment4: AttachmentInterface|null)
    {
        this.dto.attachment4 = attachment4;
    }

    public get attachment5(): AttachmentInterface|null
    {
        return this.dto.attachment5;
    }

    public set attachment5(attachment5: AttachmentInterface|null)
    {
        this.dto.attachment5 = attachment5;
    }

    public get category(): JobCategory|null
    {
        if (!this.dto.category)
        {
            return null;
        }

        return new JobCategory(this.dto.category);
    }

    private _categoryId: number|null = null;

    public get categoryId(): number|null
    {
        return this._categoryId;
    }

    public set categoryId(id: number|null)
    {
        this._categoryId = id;
    }

    public get tags(): string[]
    {
        return this.dto.tags;
    }

    public set tags(tags: string[])
    {
        this.dto.tags = tags;
    }

    public get dateCreated(): Date
    {
        return parseISO(this.dto.dateCreated);
    }

    public get dateUpdated(): Date
    {
        return parseISO(this.dto.dateUpdated);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get params(): {[key: string]: any}
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dto: any = cloneDeep(this.dto);

        dto.clientUser = this.clientUserId ? `/client_users/${ this.clientUserId }` : null;
        dto.creator = this.creatorId ? `/users/${ this.creatorId }` : null;
        dto.assignee = this.assigneeId ? `/users/${ this.assigneeId }` : null;
        dto.manager = this.managerId ? `/users/${ this.managerId }` : null;
        dto.stage = this.stageId ? `/job_stages/${ this.stageId }` : null;
        dto.category = this.categoryId ? `/job_categories/${ this.categoryId }` : null;

        return dto;
    }
}