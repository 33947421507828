import AbstractGetTask from '../abstract-get-task';
import Exception from '@vertical-plus/vue-js-api-platform/dist/model/exception/exception';
import Job from '@/model/entity/job/job';
import JobDto from '@/model/entity/job/job-dto';

export default class GetJobTask extends AbstractGetTask
{
    public id: number|null = null;

    public async run(): Promise<Job>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            const dto = response.data as JobDto;
            return new Job(dto);
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return `/jobs/${ this.id }`;
    }
}