import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import {
    VCalendarDaily,
    VCalendarWeekly,
} from 'vuetify/lib';

/*
    These two hacks for the VCalendar are to fix Vuetify breaking drag and drop since 2.2.35
    Can't figure out another way of doing it. Vuetify are adding proper drag and drop support in v3.
*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(VCalendarDaily as any).options.render = function(h: any)
{
    return h(
        'div',
        {
            class: this.classes,
            on: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
                dragstart: (e: any) =>
                {
                // E.preventDefault();
                },
            },
            directives: [
                {
                    modifiers: {
                        quiet: true,
                    },
                    name: 'resize',
                    value: this.onResize,
                },
            ],
        },
        [ this.hideHeader ? '' : this.genHead(), this.genBody() ],
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(VCalendarWeekly as any).options.render = function(h: any)
{
    return h(
        'div',
        {
            staticClass: this.staticClass,
            class: this.classes,
            on: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
                dragstart: (e: any) =>
                {
                // E.preventDefault();
                },
            },
        },
        [ this.hideHeader ? '' : this.genHead(), ...this.genWeeks() ],
    );
};

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        options: {
            customProperties: true,
        },

        themes: {
            light: {
                primary: '#3f5e72',
                secondary: '#274659',
                vpred: '#e52421',
            },
        },
    },
});