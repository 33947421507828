import AbstractPostTask from '../abstract-post-task';
import CredentialsVo from '@/model/vo/authentication/credentials-vo';

export default class LoginTask extends AbstractPostTask
{
    public username!: string;
    public password!: string;

    public async run(): Promise<CredentialsVo|null|void>
    {
        const response = await this.execute();

        if (response.status === this.successCode)
        {
            return response.data;
        }

        this.handleErrorResponse(response.status);
    }

    protected get url(): string
    {
        return '/partners/login';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}
    {
        return {
            username: this.username,
            password: this.password,
        };
    }

    protected get successCode(): number
    {
        return 200;
    }

    protected get authenticated(): boolean
    {
        return false;
    }
}