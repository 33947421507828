import { saveAs } from 'file-saver';
import AbstractGetTask from '../abstract-get-task';
import AttachmentInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/attachment-interface';
import Exception from '@vertical-plus/vue-js-api-platform/dist/model/exception/exception';
import axios from 'axios';

export default class DownloadAttachmentTask extends AbstractGetTask
{
    public attachment!: AttachmentInterface;

    public async run(): Promise<void>
    {
        try
        {
            const response = await axios({
                url: this.attachment.uri,
                method: 'GET',
                responseType: 'blob',
                headers: this.headers,
            });

            saveAs(response.data, this.attachment.filename);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any)
        {
            // Throw a TokenExpired exception if this is the failure
            if (this.authenticated && this.isTokenExpired(error.response))
            {
                throw new Error(Exception.TokenExpired);
            }

            // Otherwise return the response as the code will be handled by the concrete task
            else
            {
                throw new Error(Exception.UnknownError);
            }
        }
    }

    protected get url(): string
    {
        return this.attachment.uri ?? '';
    }
}