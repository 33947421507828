import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import JobContributionEntity from '@/model/entity/job/job-contribution';
import JobContributionForm from '../job-contribution-form/index.vue';

@Component({
    components:
    {
        'app-job-contribution-form': JobContributionForm,
    },
})
export default class JobContribution extends Vue
{
    @Prop()
    private contribution!: JobContributionEntity;

    @Prop({
        type: Boolean,
        default: false,
    })
    private edit!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private readonly!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private initial!: boolean;

    private isEditing = false;
    private isDocked = false;
    private isFullscreen = false;
    private isMinimised = false;

    private saving = false;

    created()
    {
        this.onEditChanged();
    }

    @Watch('edit')
    private onEditChanged()
    {
        this.isEditing = this.edit;

        if (this.edit)
        {
            this.$lockPage();
        }
    }

    /**
     * Handles edit requests
     */

    private onEdit()
    {
        this.isEditing = true;
        this.$lockPage();
    }

    /**
     * Handles edit cancellations
     */

    private async onCancel()
    {
        this.isEditing = false;
        this.$unlockPage();

        this.isDocked = false;
        this.isFullscreen = false;
        this.isMinimised = false;

        this.$emit('cancel');
    }

    /**
     * Toggle minimised view
     */

    private onToggleMinimise()
    {
        if (!this.isDocked && !this.isFullscreen)
        {
            return;
        }

        this.isMinimised = !this.isMinimised;
    }

    /**
     * Toggle docked editing
     */

    private onToggleDocked()
    {
        this.isDocked = !this.isDocked;
        this.isFullscreen = false;
        this.isMinimised = false;

        this.$emit('docked');
    }

    /**
     * Toggles fullscreen editing
     */

    private onToggleFullscreen()
    {
        this.isFullscreen = !this.isFullscreen;
        this.isDocked = false;
        this.isMinimised = false;
    }

    /**
     * Handles saving a contribution
     */

    private async onSave(jobContribution: JobContributionEntity)
    {
        try
        {
            this.saving = true;

            if (jobContribution.id)
            {
                await this.$serviceContainer.jobService.updateJobContribution(jobContribution);
            }
            else
            {
                await this.$serviceContainer.jobService.createJobContribution(jobContribution);
            }

            EventBus.instance.$emit('reload-job-counts');

            this.isEditing = false;
            this.$unlockPage();

            this.isDocked = false;
            this.isFullscreen = false;
            this.isMinimised = false;

            this.$emit('saved');
        }
        catch (error: unknown)
        {
            EventBus.instance.$emit(
                'notification', this.$t('job.error.failed_saving_contribution'), 'error',
            );
        }
        finally
        {
            this.saving = false;
        }
    }
}