import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import AttachmentInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/attachment-interface';
import FormFieldInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/form-field-interface';
import FormFieldSetInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/form-field-set-interface';
import FormInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/form-interface';
import Job from '@/model/entity/job/job';
import JobDto from '@/model/entity/job/job-dto';
import JobService from '@/model/service/job/job-service';

@Component
export default class JobForm extends Vue
{
    @Prop()
    private job!: Job;

    @Prop({
        type: Boolean,
        default: false,
    })
    private saving!: boolean;

    private form: FormInterface|null = null;

    private created()
    {
        this.onJobChanged();
    }

    @Watch('job')
    private onJobChanged()
    {
        this.generateForm();
    }

    /**
     * Handles form saves
     *
     * @param form
     */

    private async onSave(form: FormInterface)
    {
        if (!this.job)
        {
            return;
        }

        // Generate a job from the form data
        const job = this.generateJobFromForm(form);

        // Emit a save event with the job
        this.$emit('save', job);
    }

    /**
     * Generates the form from the job
     */

    private generateForm()
    {
        if (!this.job)
        {
            this.form = null;
            return;
        }

        const form: FormInterface =
        {
            items: [
                {
                    key: 'category',
                    type: 'integer',
                    title: this.$t('job.field.category').toString(),
                    value: this.job.categoryId,
                    required: true,
                    optionsCallback: async(search: string) => this.$serviceContainer.jobService.jobCategoryFormOptionsCallback(search),
                    titlesCallback: async(values: string[]) => this.$serviceContainer.jobService.jobCategoryFormTitlesCallback(values),
                    disableFilter: true,
                },
                {
                    key: 'priority',
                    type: 'integer',
                    title: this.$t('job.field.priority').toString(),
                    value: this.job.priority,
                    required: true,
                    options: JobService.priorityOptions,
                    dropdown: true,
                },
                {
                    key: 'name',
                    type: 'string',
                    title: this.$t('job.field.request_title').toString(),
                    value: this.job.name,
                    required: true,
                },
                {
                    key: 'description',
                    type: 'string',
                    title: this.$t('job.field.request_description').toString(),
                    value: this.job.description,
                    required: true,
                    multiline: true,
                    rich: true,
                    markdown: true,
                },
                {
                    key: 'attachment1',
                    type: 'file',
                    title: this.$t('job.field.attachment1').toString(),
                    value: this.job.attachment1,
                    required: false,
                    emitDownload: true,
                },
                {
                    key: 'attachment2',
                    type: 'file',
                    title: this.$t('job.field.attachment2').toString(),
                    value: this.job.attachment2,
                    required: false,
                    emitDownload: true,
                },
                {
                    key: 'attachment3',
                    type: 'file',
                    title: this.$t('job.field.attachment3').toString(),
                    value: this.job.attachment3,
                    required: false,
                    emitDownload: true,
                },
                {
                    key: 'attachment4',
                    type: 'file',
                    title: this.$t('job.field.attachment4').toString(),
                    value: this.job.attachment4,
                    required: false,
                    emitDownload: true,
                },
                {
                    key: 'attachment5',
                    type: 'file',
                    title: this.$t('job.field.attachment5').toString(),
                    value: this.job.attachment5,
                    required: false,
                    emitDownload: true,
                },
            ],
        };

        this.form = Object.assign({}, form);
    }

    /**
     * Generates the job from the form
     */

    private generateJobFromForm(form: FormInterface): Job
    {
        if (!this.job)
        {
            return new Job(new JobDto());
        }

        const job = new Job(cloneDeep(this.job.dto));

        this.addFieldValues(job, form.items);

        return job;
    }

    /**
     * Adds field values from form items to the passed job
     *
     * @param job
     * @param items
     */

    private addFieldValues(job: Job, items: (FormFieldSetInterface | FormFieldInterface)[])
    {
        for (const item of items)
        {
            if (!('key' in item))
            {
                this.addFieldValues(job, item.fields);
                continue;
            }

            switch (item.key)
            {
            case 'category':
                job.categoryId = item.value as number;
                break;

            case 'priority':
                job.priority = item.value as number;
                break;

            case 'name':
                job.name = item.value as string;
                break;

            case 'description':
                job.description = item.value as string;
                break;

            case 'attachment1':
                job.attachment1 = item.value as AttachmentInterface;
                break;

            case 'attachment2':
                job.attachment2 = item.value as AttachmentInterface;
                break;

            case 'attachment3':
                job.attachment3 = item.value as AttachmentInterface;
                break;

            case 'attachment4':
                job.attachment4 = item.value as AttachmentInterface;
                break;

            case 'attachment5':
                job.attachment5 = item.value as AttachmentInterface;
                break;
            }
        }
    }

    /**
     * Handles file download requests
     *
     * @param attachment
     */

    private onDownload(attachment: AttachmentInterface)
    {
        this.$serviceContainer.jobService.downloadAttachment(attachment);
    }
}