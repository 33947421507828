import AuthenticationService from './authentication/authentication-service';
import JobService from './job/job-service';
import PartnerService from './partner/partner-service';
import RouterService from './navigation/router-service';
import StoreInterface from '@vertical-plus/vue-js-micro-service-components/lib/store/store-interface';

export default class ServiceContainer
{
    private store!: StoreInterface;

    private _authenticationService!: AuthenticationService;
    private _partnerService!: PartnerService;
    private _jobService!: JobService;
    private _routerService!: RouterService;

    public constructor(store: StoreInterface)
    {
        this.store = store;
    }

    /**
     * Returns the authentication service
     */

    public get authenticationService(): AuthenticationService
    {
        if (!this._authenticationService)
        {
            this._authenticationService = new AuthenticationService(this.store);
        }

        return this._authenticationService;
    }

    /**
     * Returns the partner service
     */

    public get partnerService(): PartnerService
    {
        if (!this._partnerService)
        {
            this._partnerService = new PartnerService(this.store, this.authenticationService);
        }

        return this._partnerService;
    }

    /**
     * Returns the job service
     */

    public get jobService(): JobService
    {
        if (!this._jobService)
        {
            this._jobService = new JobService(this.store, this.authenticationService);
        }

        return this._jobService;
    }

    /**
     * Returns the router service
     */

    public get routerService(): RouterService
    {
        if (!this._routerService)
        {
            this._routerService = new RouterService(this.store, this.authenticationService);
        }

        return this._routerService;
    }
}