import AbstractPostTask from '../abstract-post-task';

export default class ResetPasswordTask extends AbstractPostTask
{
    public email!: string;

    public async run(): Promise<void>
    {
        const response = await this.execute();

        if (response.status === this.successCode)
        {
            return;
        }

        this.handleErrorResponse(response.status);
    }

    protected get url(): string
    {
        return '/partners/reset-password';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}
    {
        return {
            email: this.email,
        };
    }

    protected get successCode(): number
    {
        return 200;
    }

    protected get authenticated(): boolean
    {
        return false;
    }
}