import ActiveFiltersInterface from '@vertical-plus/vue-js-components/lib/model/vos/search/active-filters-interface';
import ActiveSettingsInterface from '@vertical-plus/vue-js-components/lib/model/vos/report/active-settings-interface';
import ColumnInterface from '@vertical-plus/vue-js-components/lib/model/vos/report/column-interface';
import DataTableReportInterface from '@vertical-plus/vue-js-components/lib/model/services/report/data-table-report-interface';
import FilterInterface from '@vertical-plus/vue-js-components/lib/model/vos/search/filter-interface';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import SavedSearchInterface from '@vertical-plus/vue-js-components/lib/model/vos/report/saved-search-interface';
import SavedSearchSearchResultsVo from '@vertical-plus/vue-js-micro-service-components/lib/model/vo/report/saved-search-search-results-vo';
import SearchConditionsInterface from '@vertical-plus/vue-js-components/lib/model/vos/search/api-platform/search-conditions-interface';
import SearchResultsVo from '@vertical-plus/vue-js-components/lib/model/vos/search/api-platform/search-results-vo';
import ServiceContainer from '../service-container';
import SettingInterface from '@vertical-plus/vue-js-components/lib/model/vos/report/setting-interface';
import SortByInterface from '@vertical-plus/vue-js-components/lib/model/vos/report/sort-by-interface';
import WidgetInterface from '@vertical-plus/vue-js-components/lib/model/vos/report/widget-interface';

export abstract class AbstractVpaDataTableReport implements DataTableReportInterface
{
    protected savedDefaultColumns: string[]|null = null;

    public get widgets(): WidgetInterface[]
    {
        return [];
    }

    public get filters(): FilterInterface[]
    {
        return [];
    }

    public get defaultFilters(): ActiveFiltersInterface
    {
        return {};
    }

    public get settings(): SettingInterface[]
    {
        return [];
    }

    public get defaultSettings(): ActiveSettingsInterface
    {
        return {};
    }

    public get columns(): ColumnInterface[]
    {
        return [];
    }

    public get defaultColumns(): string[]
    {
        return [];
    }

    public get sortBys(): SortByInterface[]
    {
        return [];
    }

    public defaultItemsPerPage = 20;
    public defaultSortBy = '';

    protected serviceContainer!: ServiceContainer;

    public constructor(serviceContainer: ServiceContainer)
    {
        this.serviceContainer = serviceContainer;
    }

    public id?: string | undefined;

    public prepared = false;

    public async prepare(): Promise<void>
    {
        this.prepared = true;
    }

    public loadResults(conditions: SearchConditionsInterface): Promise<SearchResultsVo>
    {
        throw new Error('Method not implemented.');
    }

    public downloadResults(conditions: SearchConditionsInterface, format: string): Promise<void>
    {
        throw new Error('Method not implemented.');
    }

    public loadSavedSearches(): Promise<SavedSearchSearchResultsVo>
    {
        throw new Error('Method not implemented.');
    }

    public saveSearch(search: SavedSearchInterface): Promise<SavedSearchInterface>
    {
        throw new Error('Method not implemented.');
    }

    public deleteSavedSearch(id: string): Promise<void>
    {
        throw new Error('Method not implemented.');
    }

    public pinSavedSearch(id: string): Promise<void>
    {
        throw new Error('Method not implemented.');
    }

    public unpinSavedSearch(id: string): Promise<void>
    {
        throw new Error('Method not implemented.');
    }

    public saveDefaultColumns(columns: string[], global: boolean): Promise<void>
    {
        throw new Error('Method not implemented.');
    }

    /**
     * Returns the translation for an i18n key
     *
     * @param key
     * @param params
     * @returns
     */

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected i18n(key: string, params: any = {}): string
    {
        return LocaleHelper.getI18n().t(key, params)
            .toString();
    }
}