import { cloneDeep } from 'lodash';
import ClientUserDto from './client-user-dto';

export default class ClientUser
{
    public dto!: ClientUserDto;

    public constructor(dto: ClientUserDto)
    {
        this.dto = Object.assign({}, dto);
    }

    public get id(): number|null
    {
        return this.dto.id;
    }

    public set id(id: number|null)
    {
        this.dto.id = id;
    }

    public get title(): string
    {
        return this.dto.title;
    }

    public set title(title: string)
    {
        this.dto.title = title;
    }

    public get firstName(): string
    {
        return this.dto.firstName;
    }

    public set firstName(firstName: string)
    {
        this.dto.firstName = firstName;
    }

    public get lastName(): string
    {
        return this.dto.lastName;
    }

    public set lastName(lastName: string)
    {
        this.dto.lastName = lastName;
    }

    public get fullName()
    {
        return (`${ this.firstName } ${ this.lastName }`).trim();
    }

    public get email(): string
    {
        return this.dto.email;
    }

    public set email(email: string)
    {
        this.dto.email = email;
    }

    public get username(): string
    {
        return this.dto.username;
    }

    public set username(username: string)
    {
        this.dto.username = username;
    }

    public get password(): string
    {
        return this.dto.password;
    }

    public set password(password: string)
    {
        this.dto.password = password;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get params(): {[key: string]: any}
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dto: any = cloneDeep(this.dto);

        return dto;
    }
}