const messages =
{
    en:
    {
        authentication:
        {
            action:
            {
                edit: 'Update account details',
            },
            error:
            {
                failed_updating_account_details: 'Failed updating account details - please contact support if this continues',
                login_failed_invalid_details: 'Login failed - invalid details',
                login_failed_server_error: 'Error logging in - please contact support if this continues',
                password_must_be_characters: 'Must be {length} characters or more',
                passwords_must_match: 'Passwords must match',
            },
            field:
            {
                title: 'Title',
                first_name: 'First Name',
                last_name: 'Last Name',
                email: 'Email',
                username: 'Username',
                password: 'Password',
                password_confirmation: 'Password (confirmation)',
            },
            message:
            {
                account_details_updated: 'Account details updated successfully',
                forgotten_password_description: 'If you have forgotten your password please enter your email address below and click submit to reset it.',
                forgotten_password_title: 'Forgotten your password?',
                password_reset: 'Your request has been received - if a matching account was found a new password will be sent to your inbox shortly.',
            },
        },
        contact:
        {
            message:
            {
                title: 'Contact Us',
                description: 'For any support issues or queries please contact us on 01752 764400 or via info@verticalplus.co.uk',
            },
        },
        general:
        {
            action:
            {
                cancel: 'Cancel',
                close: 'Close',
                delete: 'Delete',
                edit: 'Edit',
                no: 'No',
                ok: 'Ok',
                save: 'Save',
                search: 'Search',
                submit: 'Submit',
                yes: 'Yes',
            },
            error:
            {
                communication_error: 'Communication Error',
                communication_error_description: 'There\'s a problem with your connection to the server.',
                communication_error_contact: 'If the problem persists please contact support.',
                failed_deleting_not_found: 'Failed deleting - the item could not be found',
                failed_deleting_server_error: 'Failed deleting - please contact support if this continues',
                failed_loading_not_found: 'Failed loading - the item could not be found',
                failed_loading_server_error: 'Failed loading - please contact support if this continues',
                failed_saving_invalid_details: 'Failed saving due to invalid details',
                failed_saving_server_error: 'Failed saving - please contact support if this continues',
                file_size_limit: 'File is too large - 100MB max allowed',
                somethings_gone_wrong: 'Something\'s gone wrong. Please refresh and try again.',
            },
            field:
            {
                ascending: 'Ascending',
                descending: 'Descending',
                search_term: 'Search Term',
            },
            message:
            {
                app_title: 'Partner Management System',
                app_title_full: 'Vertical Plus Partner Management System',
                max_number_attachments: 'Maximum of {number} attachments',
                search_results: 'Search Results',
                warning: 'Warning',
                welcome_back_name: 'Welcome back {name}!',
            },
        },
        home:
        {
            message:
            {
                title: 'Welcome to the Partner Management System',
                description: 'This system provides support and ticket functionality for clients of Vertical Plus.',
                forgotten_password: 'If you have forgotten your password <a href="{url}">click here</a>',
                getting_started: 'To get started please login using the form in the top right of the screen.',
            },
        },
        job:
        {
            action:
            {
                add_contribution: 'Add New Message',
                close_job: 'Close Job',
                close_job_tooltip: 'Mark this job as closed',
                submit_request: 'Submit Request',
            },
            error:
            {
                attachment_limit: 'There is a limit of {number} attachments',
                failed_closing_job: 'Failed closing job - please contact support if this continues',
                failed_downloading_attachment: 'Failed downloading attachment - please contact support if this continues',
                failed_saving_contribution: 'Failed saving message - please contact support if this continues',
                failed_submitting_job_request: 'Error submitting job request - please contact support if this continues',
            },
            field:
            {
                assigned_to: 'Assigned To',
                attachment: 'Attachment',
                attachment1: 'Attachment 1',
                attachment2: 'Attachment 2',
                attachment3: 'Attachment 3',
                attachment4: 'Attachment 4',
                attachment5: 'Attachment 5',
                attachments: 'Attachments',
                author: 'Author',
                category: 'Category',
                contributions: 'Latest Updates',
                date_added: 'Date Added',
                date_scheduled: 'Date Scheduled',
                details: 'Details',
                last_update: 'Last Update',
                my_jobs_only: 'My Jobs Only',
                name: 'Name',
                owner: 'Owner',
                priority: 'Priority',
                ref: 'Ref',
                request_description: 'Description of change proposed',
                request_title: 'Request Title',
                stage: 'Stage',
            },
            message:
            {
                close_job_confirmation_title: 'Close Job',
                close_job_confirmation_body: 'Are you sure you want to close this job?',
                closed_successfully: 'Job closed successfully',
                job_requested_submitted: 'Job request submitted successfully',
                job_search: 'Job Search',
                title_jobs_awaiting_input: 'Jobs Awaiting Input',
                title_jobs_awaiting_approval: 'Jobs Awaiting Approval',
                title_jobs_completed: 'Completed Jobs',
                title_jobs_current: 'Current Jobs',
                title_jobs_pending: 'Pending Jobs',
                title_jobs_search: 'Search results',
                title_new_job_request: 'New Job Request',
                urgent_priority_warning: 'This priority should only be used if there is a severe issue affecting your site',
            },
        },
        navbar:
        {
            message:
            {
                home: 'Home',
                job_search: 'Job Search',
                jobs: 'Jobs',
                jobs_current: 'Current',
                jobs_pending: 'Pending',
                jobs_awaiting_input: 'Awaiting Input',
                jobs_awaiting_approval: 'Awaiting Approval',
                jobs_complete: 'Complete',
                jobs_add_new_job: 'Add New Job',
                my_account: 'My Account',
            },
        },
        search:
        {
            message:
            {
                no_results_found: 'No results found',
            },
        },
    },
};

export default messages;