import { AxiosResponse } from 'axios';
import MicroServiceAbstractTask from '@vertical-plus/vue-js-micro-service-components/lib/model/task/abstract-task';

export default abstract class AbstractTask extends MicroServiceAbstractTask
{
    /**
     * Returns the headers for the request
     */

    protected get headers(): Record<string, string>
    {
        const headers: Record<string, string> = {};

        // Add the token if this is an authenticated request
        if (this.authenticated && this.token)
        {
            headers.Authorization = `ClientToken ${ this.token }`;
        }

        const customHeaders = this.customHeaders;

        for (const key in customHeaders)
        {
            headers[key] = customHeaders[key];
        }

        return headers;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected isTokenExpired(response: AxiosResponse<any>): boolean
    {
        return (response.status === 401 && response.data.message === 'JWT Token not found');
    }
}