import { Component } from 'vue-property-decorator';
import AbstractView from '@/views/abstract-view';
import JobIndex from '@/components/job-index/index.vue';

@Component({
    components: {
        'app-job-index': JobIndex,
    },
})
export default class JobIndexView extends AbstractView
{
    protected get title(): string
    {
        switch (this.$route.params.type)
        {
        case 'current':
            return this.$t('job.message.title_jobs_current').toString();

        case 'pending':
            return this.$t('job.message.title_jobs_pending').toString();

        case 'awaiting-input':
            return this.$t('job.message.title_jobs_awaiting_input').toString();

        case 'awaiting-approval':
            return this.$t('job.message.title_jobs_awaiting_approval').toString();

        case 'complete':
            return this.$t('job.message.title_jobs_completed').toString();

        case 'search':
            return this.$t('general.message.search_results').toString();

        default:
            return this.$t('navbar.message.jobs').toString();
        }
    }
}