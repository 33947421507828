import { AbstractVpaDataTableReport } from '../../report/abstract-vpa-data-table-report';
import ActiveSettingsInterface from '@vertical-plus/vue-js-components/lib/model/vos/report/active-settings-interface';
import ClientUser from '@/model/entity/client/client-user';
import ColumnInterface from '@vertical-plus/vue-js-components/lib/model/vos/report/column-interface';
import JobCategory from '@/model/entity/job/job-category';
import JobSearchConditionsInterface from '@/model/vo/job/job-search-conditions-interface';
import JobSearchResultsVo from '@/model/vo/job/job-search-results-vo';
import JobService from '../job-service';
import ServiceContainer from '../../service-container';
import SettingInterface from '@vertical-plus/vue-js-components/lib/model/vos/report/setting-interface';
import SortByInterface from '@vertical-plus/vue-js-components/lib/model/vos/report/sort-by-interface';

export default class JobDataTableReport extends AbstractVpaDataTableReport
{
    public id = 'jobs';

    public get columns(): ColumnInterface[]
    {
        return [
            {
                key: 'id',
                title: this.i18n('job.field.ref'),
                width: 100,
            },
            {
                key: 'category.name',
                title: this.i18n('job.field.category'),
                width: 150,
            },
            {
                key: 'name',
                title: this.i18n('job.field.name'),
            },
            {
                key: 'stage.name',
                title: this.i18n('job.field.stage'),
                width: 175,
            },
            {
                key: 'priority',
                title: this.i18n('job.field.priority'),
                width: 100,
            },
            {
                key: 'clientUser',
                title: this.i18n('job.field.owner'),
                width: 150,
            },
            {
                key: 'assignee',
                title: this.i18n('job.field.assigned_to'),
                width: 150,
            },
            {
                key: 'dateCreated',
                title: this.i18n('job.field.date_added'),
                width: 150,
            },
        ];
    }

    public get defaultColumns(): string[]
    {
        if (this.savedDefaultColumns)
        {
            return this.savedDefaultColumns;
        }

        return [
            'id',
            'category.name',
            'name',
            'stage.name',
            'priority',
            'clientUser',
            'assignee',
            'dateCreated',
        ];
    }

    public get settings(): SettingInterface[]
    {
        return [
            {
                key: 'category',
                title: this.i18n('job.field.category'),
                type: 'select',
                options: this.categoryOptions,
            },
            {
                key: 'mine',
                title: this.i18n('job.field.my_jobs_only'),
                type: 'select',
                options: [
                    {
                        value: 0,
                        title: 'No',
                    },
                    {
                        value: '1',
                        title: 'Yes',
                    },
                ],
            },
        ];
    }

    public get defaultSettings(): ActiveSettingsInterface
    {
        return {
            category: '',
            mine: 0,
        };
    }

    public get sortBys(): SortByInterface[]
    {
        return [
            {
                key: 'id',
                title: this.i18n('job.field.ref'),
                order:
                [
                    {
                        key: 'id',
                        descending: false,
                    },
                ],
            },
            {
                key: 'name',
                title: this.i18n('job.field.name'),
                order:
                [
                    {
                        key: 'name',
                        descending: false,
                    },
                ],
            },
            {
                key: 'dateCreated',
                title: this.i18n('job.field.date_added'),
                order:
                [
                    {
                        key: 'dateCreated',
                        descending: true,
                    },
                ],
            },
        ];
    }

    public defaultSortBy = 'dateCreated';

    private clientUser!: ClientUser;
    private type!: string;
    private categories: JobCategory[] = [];
    private results: JobSearchResultsVo|null = null;

    public constructor(
        serviceContainer: ServiceContainer,
        clientUser: ClientUser,
        type: string,
    )
    {
        super(serviceContainer);

        this.clientUser = clientUser;
        this.type = type;

        this.loadCategories();
    }

    public setType(type: string)
    {
        this.type = type;
    }

    public async loadCategories()
    {
        const results = await this.serviceContainer.jobService.findJobCategories({
            order: [
                {
                    key: 'name',
                    descending: false,
                },
            ],
        });

        this.categories = results.results;
    }

    public async loadResults(conditions: JobSearchConditionsInterface): Promise<JobSearchResultsVo>
    {
        if (conditions.mine === '1')
        {
            conditions.clientUser = this.clientUser.id ?? undefined;
        }
        else if (conditions.clientUser)
        {
            delete conditions.clientUser;
        }

        switch (this.type)
        {
        case 'pending':
            conditions.assignee = 105;
            conditions.stage = undefined;
            conditions.complete = false;
            break;

        case 'awaiting-input':
            conditions.assignee = undefined;
            conditions.stage = JobService.STAGE_WAITING_FOR_CLIENT;
            conditions.complete = false;
            break;

        case 'awaiting-approval':
            conditions.assignee = undefined;
            conditions.stage = JobService.STAGE_ACCEPTANCE_TESTING;
            conditions.complete = false;
            break;

        case 'complete':
            conditions.assignee = undefined;
            conditions.stage = undefined;
            conditions.complete = true;
            break;

        case 'search':
            conditions.assignee = undefined;
            conditions.stage = undefined;
            conditions.complete = undefined;
            break;

        case 'current':
        default:
            conditions.assignee = undefined;
            conditions.stage = undefined;
            conditions.complete = false;
        }

        const results = await this.serviceContainer.jobService.findJobs(conditions);

        this.results = Object.assign({}, results);

        return results;
    }

    private get categoryOptions(): SettingInterface['options']
    {
        const options: SettingInterface['options'] = [];

        options.push({
            value: '',
            title: 'All',
        });

        for (const category of this.categories)
        {
            if (!category.id)
            {
                continue;
            }

            options.push({
                value: `${ category.id }`,
                title: category.name,
            });
        }

        return options;
    }
}