
import AbstractGetTask from '../abstract-get-task';
import Exception from '@vertical-plus/vue-js-api-platform/dist/model/exception/exception';
import JobStage from '@/model/entity/job/job-stage';
import JobStageDto from '@/model/entity/job/job-stage-dto';
import JobStageSearchConditionsInterface from '@/model/vo/job/job-stage-search-conditions-interface';
import JobStageSearchResultsVo from '@/model/vo/job/job-stage-search-results-vo';
import TaskParamsInterface from '@vertical-plus/vue-js-api-platform/dist/model/task/task-params-interface';

export default class GetJobStagesTask extends AbstractGetTask
{
    public searchConditions?: JobStageSearchConditionsInterface;

    public async run(): Promise<JobStageSearchResultsVo>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            const searchResults = this.parseSearchResults(response);

            const stages: JobStage[] = [];

            for (const stageDto of searchResults.results)
            {
                const dto = stageDto as JobStageDto;

                stages.push(new JobStage(dto));
            }

            searchResults.results = stages;

            return searchResults as JobStageSearchResultsVo;
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return '/job_stages';
    }

    protected get params(): TaskParamsInterface|null
    {
        if (!this.searchConditions)
        {
            return null;
        }

        return this.generateParamsFromData(this.searchConditions);
    }
}