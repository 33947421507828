import AbstractPatchTask from '../abstract-patch-task';
import JobContribution from '@/model/entity/job/job-contribution';

export default class PatchJobContributionTask extends AbstractPatchTask
{
    public jobContribution!: JobContribution;

    public async run(): Promise<JobContribution|null|void>
    {
        const response = await this.execute();

        if (response.status === this.successCode)
        {
            return new JobContribution(response.data);
        }

        this.handleErrorResponse(response.status);
    }

    protected get url(): string
    {
        return `/job_contributions/${ this.jobContribution.id }`;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}
    {
        return this.jobContribution.params;
    }
}