import { Component, Prop, Vue } from 'vue-property-decorator';
import AttachmentInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/attachment-interface';
import FormService from '@vertical-plus/vue-js-components/lib/model/services/form/form-service';

@Component
export default class Attachment extends Vue
{
    @Prop()
    private value!: AttachmentInterface;

    /**
     * Returns the icon for the attachment
     *
     * @returns
     */

    private get attachmentIcon(): string
    {
        return FormService.getAttachmentIcon(this.value);
    }

    /**
     * Handles download requests
     */

    private onDownload()
    {
        this.$serviceContainer.jobService.downloadAttachment(this.value);
    }
}