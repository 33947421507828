import AbstractGetTask from '../abstract-get-task';
import ClientUser from '@/model/entity/client/client-user';
import ClientUserDto from '@/model/entity/client/client-user-dto';
import Exception from '@vertical-plus/vue-js-api-platform/dist/model/exception/exception';

export default class GetClientUserTask extends AbstractGetTask
{
    public id!: number;

    public async run(): Promise<ClientUser>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            const dto = response.data as ClientUserDto;
            return new ClientUser(dto);
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return `/client_users/${ this.id }`;
    }
}