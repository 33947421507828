import JobStageDto from './job-stage-dto';

export default class JobStage
{
    public dto!: JobStageDto;

    public constructor(dto: JobStageDto)
    {
        this.dto = Object.assign({}, dto);
    }

    public get id(): number|null
    {
        return this.dto.id;
    }

    public set id(id: number|null)
    {
        this.dto.id = id;
    }

    public get name(): string
    {
        return this.dto.name;
    }

    public set name(name: string)
    {
        this.dto.name = name;
    }

    public get type(): string
    {
        return this.dto.type;
    }

    public set type(type: string)
    {
        this.dto.type = type;
    }

    public get calendarOrder(): number
    {
        return this.dto.calendarOrder;
    }

    public set calendarOrder(calendarOrder: number)
    {
        this.dto.calendarOrder = calendarOrder;
    }
}