import AbstractGetTask from '../abstract-get-task';
import Exception from '@vertical-plus/vue-js-api-platform/dist/model/exception/exception';
import JobCategory from '@/model/entity/job/job-category';
import JobCategoryDto from '@/model/entity/job/job-category-dto';
import JobCategorySearchConditionsInterface from '@/model/vo/job/job-category-search-conditions-interface';
import JobCategorySearchResultsVo from '@/model/vo/job/job-category-search-results-vo';
import TaskParamsInterface from '@vertical-plus/vue-js-api-platform/dist/model/task/task-params-interface';

export default class GetJobCategoriesTask extends AbstractGetTask
{
    public searchConditions?: JobCategorySearchConditionsInterface;

    public async run(): Promise<JobCategorySearchResultsVo>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            const searchResults = this.parseSearchResults(response);

            const categories: JobCategory[] = [];

            for (const categoryDto of searchResults.results)
            {
                const dto = categoryDto as JobCategoryDto;

                categories.push(new JobCategory(dto));
            }

            searchResults.results = categories;

            return searchResults as JobCategorySearchResultsVo;
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return '/job_categories';
    }

    protected get params(): TaskParamsInterface|null
    {
        if (!this.searchConditions)
        {
            return null;
        }

        return this.generateParamsFromData(this.searchConditions);
    }
}