import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import JobCountsInterface from '@/model/vo/job/job-counts-interface';
import MenuItemInterface from '@/model/vo/navigation/menu-item-interface';

@Component
export default class NavbarComponent extends Vue
{
    private searchTerm = '';
    private jobCounts: JobCountsInterface|null = null;

    private reloadListener!: () => void;

    private created()
    {
        this.loadJobCounts();

        this.reloadListener = () =>
        {
            this.loadJobCounts();
        };

        EventBus.instance.$on('reload-job-counts', this.reloadListener);
    }

    private beforeDestroy()
    {
        EventBus.instance.$off('reload-job-counts', this.reloadListener);
    }

    /**
     * Returns the visibility of the navbar
     */

    private get visible(): boolean
    {
        return this.$store.getters['ui/navbarVisible'];
    }

    /**
       * Sets the visibility of the navbar
       */

    private set visible(navbarVisible: boolean)
    {
        this.$store.commit('ui/navbarVisible', navbarVisible);
    }

    /**
     * Returns the base items for the navbar
     */

    private get items(): MenuItemInterface[]
    {
        const items: MenuItemInterface[] = [];

        // General items
        items.push(...[
            {
                title: this.$t('navbar.message.jobs_add_new_job').toString(),
                route: {
                    name: 'job-create',
                },
                icon: 'mdi-plus',
                active: true,
            },
            {
                title: this.$t('navbar.message.jobs_current').toString(),
                route: {
                    name: 'job-index',
                    params: {
                        type: 'current',
                    },
                },
                icon: 'mdi-pin',
                badge: this.jobCounts?.current,
                active: true,
            },
            {
                title: this.$t('navbar.message.jobs_pending').toString(),
                route: {
                    name: 'job-index',
                    params: {
                        type: 'pending',
                    },
                },
                icon: 'mdi-timer-sand',
                badge: this.jobCounts?.pending,
                active: true,
            },
            {
                title: this.$t('navbar.message.jobs_awaiting_input').toString(),
                route: {
                    name: 'job-index',
                    params: {
                        type: 'awaiting-input',
                    },
                },
                icon: 'mdi-message-alert',
                badge: this.jobCounts?.awaitingInput,
                active: true,
            },
            {
                title: this.$t('navbar.message.jobs_awaiting_approval').toString(),
                route: {
                    name: 'job-index',
                    params: {
                        type: 'awaiting-approval',
                    },
                },
                icon: 'mdi-signature-freehand',
                badge: this.jobCounts?.awaitingApproval,
                active: true,
            },
            {
                title: this.$t('navbar.message.jobs_complete').toString(),
                route: {
                    name: 'job-index',
                    params: {
                        type: 'complete',
                    },
                },
                icon: 'mdi-clipboard-check',
                badge: this.jobCounts?.complete,
                active: true,
            },
        ] as MenuItemInterface[]);

        return items;
    }

    /**
     * Returns user specific menu items taking into account role access.
     */

    private get userItems(): MenuItemInterface[]
    {
        if (!this.$clientUser)
        {
            return [];
        }

        const items: MenuItemInterface[] = [];

        for (const item of this.items)
        {
            if (!this.userCanSeeItem(item))
            {
                continue;
            }

            const clone = Object.assign({}, item);

            if (item.children)
            {
                clone.children = [];

                for (const childItem of item.children)
                {
                    if (!this.userCanSeeItem(childItem))
                    {
                        continue;
                    }

                    clone.children.push(childItem);
                }
            }

            items.push(clone);
        }

        return items;
    }

    /**
     * Returns whether the user can see the given item.
     *
     * @param item
     */

    private userCanSeeItem(item: MenuItemInterface): boolean
    {
        return true;
    }

    /**
     * Loads the job counts
     */

    private async loadJobCounts()
    {
        const jobCounts = await this.$serviceContainer.jobService.loadJobCounts();

        this.jobCounts = Object.assign({}, jobCounts);
    }

    /**
     * Handles job search
     */

    private onJobSearch()
    {
        const route: RawLocation = {
            name: 'job-index',
            params: {
                type: 'search',
            },
        };

        if (this.searchTerm)
        {
            if (!route.query)
            {
                route.query = {};
            }

            route.query.search = this.searchTerm;
        }

        this.$router.push(route);

        // Clear the search
        this.searchTerm = '';
    }
}