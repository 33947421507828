import { Component } from 'vue-property-decorator';
import AbstractView from '../abstract-view';

@Component
export default class HomeView extends AbstractView
{
    /**
     * Returns the forgotten password string url
     */

    private get forgottenPasswordUrl(): string
    {
        const route =
        {
            name: 'forgotten-password',
        };

        const routeResolved = this.$router.resolve(route);

        return routeResolved.href;
    }
}