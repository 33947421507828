import AbstractGetTask from '../abstract-get-task';
import Exception from '@vertical-plus/vue-js-api-platform/dist/model/exception/exception';
import Job from '@/model/entity/job/job';
import JobDto from '@/model/entity/job/job-dto';
import JobSearchConditionsInterface from '@/model/vo/job/job-search-conditions-interface';
import JobSearchResultsVo from '@/model/vo/job/job-search-results-vo';
import TaskHelper from '../task-helper';
import TaskParamsInterface from '@vertical-plus/vue-js-api-platform/dist/model/task/task-params-interface';

export default class GetJobsTask extends AbstractGetTask
{
    public searchConditions?: JobSearchConditionsInterface;

    public async run(): Promise<JobSearchResultsVo>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            const searchResults = this.parseSearchResults(response);
            const meta = response.data['hydra:meta'];

            const jobs: Job[] = [];

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            for (const jobDto of (searchResults.results as any[]))
            {
                this.addAssociations(meta, jobDto);

                const dto = jobDto as JobDto;

                jobs.push(new Job(dto));
            }

            searchResults.results = jobs;

            return searchResults as JobSearchResultsVo;
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return '/jobs';
    }

    protected get params(): TaskParamsInterface|null
    {
        if (!this.searchConditions)
        {
            return null;
        }

        return this.generateParamsFromData(this.searchConditions);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private addAssociations(meta: any, jobDto: any)
    {
        // Add the users
        jobDto.assignee = TaskHelper.getItemFromMeta(meta.users, jobDto.assignee);
        jobDto.creator = TaskHelper.getItemFromMeta(meta.users, jobDto.creator);
        jobDto.manager = TaskHelper.getItemFromMeta(meta.users, jobDto.manager);

        // Add the client
        jobDto.client = TaskHelper.getItemFromMeta(meta.clients, jobDto.client);

        // Add the client user
        jobDto.clientUser = TaskHelper.getItemFromMeta(meta.client_users, jobDto.clientUser);

        // Add the parent job
        jobDto.parentJob = TaskHelper.getItemFromMeta(meta.jobs, jobDto.parentJob);

        // Add the stage
        jobDto.stage = TaskHelper.getItemFromMeta(meta.job_stages, jobDto.stage);
    }
}