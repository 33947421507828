import { Method } from 'axios';
import AbstractTask from './abstract-task';

export default abstract class AbstractPatchTask extends AbstractTask
{
    protected get method(): Method
    {
        return 'PATCH';
    }

    protected get successCode(): number
    {
        return 200;
    }

    protected get customHeaders(): Record<string, string>
    {
        return {
            'Content-Type': 'application/merge-patch+json',
        };
    }
}