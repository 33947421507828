import { NavigationGuardNext, Route } from 'vue-router';
import AbstractAuthenticatedService from '../abstract-authenticated-service';

export default class RouterService extends AbstractAuthenticatedService
{
    public handle(
        to: Route,
        from: Route,
        next: NavigationGuardNext,
        loginRoute: Route,
        landingPageRoute: Route,
    ): void
    {
        // Load the current user
        const user = this.store.getters['app/clientUser'];

        switch (true)
        {
        case (!!(to.meta?.requiresAuth && !user)):
            // If the page requires auth but there's no user, redirect to login
            this.store.commit('authentication/loginRedirectUrl', to.fullPath);
            next(loginRoute.path);
            break;

        case (!!(to.meta?.noUser && user)):
            // If the page shouldn't have a user (login/forgot password) and there is a user then go to the landing page
            next(landingPageRoute.path);
            break;

        default:
            // Default carry on to the next page
            next();
            break;
        }
    }
}