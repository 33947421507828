/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default {
    namespaced: true,
    state: (): any => ({
        jobContributionsDescending: false,
        navbarVisible: true,
        pageLocked: false,
    }),
    mutations: {
        jobContributionsDescending(state: any, jobContributionsDescending: boolean): void
        {
            state.jobContributionsDescending = jobContributionsDescending;
        },
        navbarVisible(state: any, navbarVisible: boolean): void
        {
            state.navbarVisible = navbarVisible;
        },
        pageLocked(state: any, pageLocked: boolean): void
        {
            state.pageLocked = pageLocked;
        },
    },
    actions: {},
    getters: {
        jobContributionsDescending(state: any): boolean
        {
            return state.jobContributionsDescending;
        },
        navbarVisible(state: any): boolean
        {
            return state.navbarVisible;
        },
        pageLocked(state: any): boolean
        {
            return state.pageLocked;
        },
    },
};