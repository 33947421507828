import ServiceContainer from '../model/service/service-container';
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(
        to, from, savedPosition,
    )
    {
        if (savedPosition)
        {
            return savedPosition;
        }
        else
        {
            return {
                x: 0,
                y: 0,
            };
        }
    },
});

const serviceContainer = new ServiceContainer(store);

router.beforeEach((
    to, from, next,
) =>
{
    serviceContainer.routerService.handle(
        to,
        from,
        next,
        router.resolve({ name: 'home' }).route,
        router.resolve({
            name: 'job-index',
            params: { type: 'current' },
        }).route,
    );
});

export default router;