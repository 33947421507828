import JobCategoryDto from './job-category-dto';

export default class JobCategory
{
    public dto!: JobCategoryDto;

    public constructor(dto: JobCategoryDto)
    {
        this.dto = Object.assign({}, dto);
    }

    public get id(): number|null
    {
        return this.dto.id;
    }

    public set id(id: number|null)
    {
        this.dto.id = id;
    }

    public get name(): string
    {
        return this.dto.name;
    }

    public set name(name: string)
    {
        this.dto.name = name;
    }
}