import { Component } from 'vue-property-decorator';
import AbstractView from '@/views/abstract-view';

@Component
export default class ForgottenPasswordView extends AbstractView
{
    private email = '';
    private isReset = false;
    private submitting = false;

    protected get title(): string
    {
        return this.$t('authentication.message.forgotten_password_title').toString();
    }

    /**
     * Handles reset password requests
     */

    private async onResetPassword()
    {
        try
        {
            this.submitting = true;

            await this.$serviceContainer.authenticationService.resetPassword(this.email);
        }
        finally
        {
            this.email = '';
            this.isReset = true;
            this.submitting = false;
        }
    }
}