import { Method } from 'axios';
import AbstractTask from './abstract-task';

export default abstract class AbstractPostTask extends AbstractTask
{
    protected get method(): Method
    {
        return 'POST';
    }

    protected get successCode(): number
    {
        return 201;
    }
}