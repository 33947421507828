/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import ClientUser from '@/model/entity/client/client-user';

export default {
    namespaced: true,
    state: (): any => ({
        clientUser: null,
    }),
    mutations: {
        clientUser(state: any, clientUser: ClientUser|null): void
        {
            state.clientUser = clientUser ? Object.assign({}, clientUser.dto) : null;
        },
    },
    actions: {},
    getters: {
        clientUser(state: any): ClientUser|null
        {
            if (!state.clientUser)
            {
                return null;
            }

            return new ClientUser(state.clientUser);
        },
    },
};