import AbstractService from '@vertical-plus/vue-js-micro-service-components/lib/model/service/abstract-service';
import AuthenticationService from './authentication/authentication-service';
import ClientUser from '../entity/client/client-user';
import Exception from '@vertical-plus/vue-js-api-platform/dist/model/exception/exception';
import StoreInterface from '@vertical-plus/vue-js-micro-service-components/lib/store/store-interface';
import TaskInterface from '@vertical-plus/vue-js-micro-service-components/lib/model/task/task-interface';

export default abstract class AbstractAuthenticatedService extends AbstractService
{
    protected authenticationService: AuthenticationService;

    /**
     * Constructor
     */

    public constructor(store: StoreInterface, authenticationService: AuthenticationService)
    {
        super(store);

        this.authenticationService = authenticationService;
    }

    /**
     * Returns the current user's id
     */

    protected get clientUserId(): number|null
    {
        const user: ClientUser|null = this.store.getters['authentication/user'];

        return user ? user.id : null;
    }

    /**
     * Runs a task, refreshing the token as appropriate
     *
     * @param task
     * @returns
     */

    protected async runTask(task: TaskInterface): Promise<unknown>
    {
        try
        {
            return await task.run();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any)
        {
            switch (error.message)
            {
            case Exception.TokenExpired:

                // Refresh the token
                await this.authenticationService.refreshToken();

                // Re-try the request with the new token
                task.token = this.token;

                return await task.run();

            default:
                throw error;
            }
        }
    }
}