import { Component, Watch } from 'vue-property-decorator';
import AbstractView from '@/views/abstract-view';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import Job from '@/model/entity/job/job';
import JobForm from '@/components/job-form/index.vue';

@Component({
    components:
    {
        'app-job-form': JobForm,
    },
})
export default class JobEditView extends AbstractView
{
    private job: Job|null = null;
    private saving = false;

    protected get title(): string
    {
        let title = this.$t('job.action.edit').toString();

        if (this.job)
        {
            title += ` - ${ this.job.name }`;
        }

        return title;
    }

    protected created()
    {
        this.loadJob();
    }

    @Watch('$route')
    private onRouteChanged()
    {
        this.loadJob();
    }

    /**
     * Loads the job
     */

    private async loadJob()
    {
        try
        {
            this.job = await this.$serviceContainer.jobService.loadJob(this.$route.params.id);
        }
        catch (error: unknown)
        {
            EventBus.instance.$emit(
                'notification',
                this.$t('general.error.failed_loading_server_error'),
                'error',
            );
        }
    }

    /**
     * Handles form saves
     *
     * @param job
     */

    private async onSave(job: Job)
    {
        try
        {
            this.saving = true;

            this.job = await this.$serviceContainer.jobService.updateJob(job);

            EventBus.instance.$emit(
                'notification',
                this.$t('general.message.saved_successfully'),
                'success',
            );
        }
        catch (error: unknown)
        {
            EventBus.instance.$emit(
                'notification',
                this.$t('general.error.failed_saving_server_error'),
                'error',
            );
        }
        finally
        {
            this.saving = false;
        }
    }
}