import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { cloneDeep } from 'lodash';
import { format } from 'date-fns';
import Job from '@/model/entity/job/job';
import JobContribution from '../job-contribution/index.vue';
import JobContributionEntity from '@/model/entity/job/job-contribution';
import JobContributions from '../job-contributions/index.vue';
import JobService from '@/model/service/job/job-service';

@Component({
    components: {
        'app-job-contribution': JobContribution,
        'app-job-contributions': JobContributions,
    },
})
export default class JobInformation extends Vue
{
    @Prop()
    private value!: Job;

    private job: Job|null = null;

    private initialContribution: JobContributionEntity|null = null;

    private created()
    {
        this.onJobChanged();
    }

    @Watch('value')
    private onJobChanged()
    {
        if (!this.value)
        {
            this.job = null;
            this.initialContribution = null;

            return;
        }

        this.job = new Job(cloneDeep(this.value.dto));

        this.initialContribution = new JobContributionEntity({
            id: null,
            job: this.job.dto,
            clientUser: this.job.creatorId && parseInt(this.job.creatorId) > 0 ? null : this.job.dto.clientUser,
            user: this.job.dto.creator,
            notes: this.job.description ?? '',
            attachment1: null,
            clientVisible: true,
            markdown: true,
            amount: '0.00',
            timeSpent: 0,
            type: null,
            deleted: false,
            dateCreated: this.job.dto.dateCreated,
        });
    }

    private get details()
    {
        if (!this.job)
        {
            return [];
        }

        return [
            {
                title: this.$t('job.field.date_added').toString(),
                value: format(this.job.dateCreated, 'dd/MM/yyyy'),
            },
            {
                title: this.$t('job.field.assigned_to').toString(),
                value: this.job.assignee ? this.job.assignee.fullName : '-',
            },
            {
                title: this.$t('job.field.stage').toString(),
                value: this.job.stage ? this.job.stage.name : '-',
            },
            {
                title: this.$t('job.field.category').toString(),
                value: this.job.category ? this.job.category.name : '-',
            },
            {
                title: this.$t('job.field.priority').toString(),
                value: JobService.getPriorityTitle(this.job.priority),
            },
        ];
    }
}