import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import AttachmentInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/attachment-interface';
import FormFieldInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/form-field-interface';
import FormFieldSetInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/form-field-set-interface';
import FormInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/form-interface';
import JobContribution from '@/model/entity/job/job-contribution';
import JobContributionDto from '@/model/entity/job/job-contribution-dto';

@Component
export default class JobContributionForm extends Vue
{
    @Prop()
    private jobContribution!: JobContribution;

    @Prop({
        type: Boolean,
        default: false,
    })
    private saving!: boolean;

    private form: FormInterface|null = null;

    $refs!:
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        form: any,
    };

    private created()
    {
        this.onJobContributionChanged();
    }

    @Watch('jobContribution')
    private onJobContributionChanged()
    {
        this.generateForm();
    }

    /**
     * Handles form cancels
     */

    private onCancel()
    {
        this.$emit('cancel');
    }

    /**
     * Handles form saves
     *
     * @param form
     */

    private async onSave(form: FormInterface)
    {
        if (!this.jobContribution)
        {
            return;
        }

        // Generate a job contribution from the form data
        const jobContribution = this.generateJobContributionFromForm(form);

        // Emit a save event with the job contribution
        this.$emit('save', jobContribution);
    }

    /**
     * Generates the form
     */

    private generateForm()
    {
        if (!this.jobContribution)
        {
            this.form = null;
            return;
        }

        const form: FormInterface =
        {
            items: [
                {
                    key: 'notes',
                    type: 'string',
                    title: this.$t('general.field.notes').toString(),
                    hideTitle: true,
                    value: this.jobContribution.notes,
                    required: true,
                    multiline: true,
                    rich: true,
                    markdown: true,
                },
                {
                    key: 'attachment1',
                    type: 'file',
                    title: this.$t('job.field.attachment').toString(),
                    value: this.jobContribution.attachment1,
                    required: false,
                    emitDownload: true,
                },
            ],
            cancelButton: true,
        };

        this.form = Object.assign({}, form);
    }

    /**
     * Generates the job contribution from the form
     */

    private generateJobContributionFromForm(form: FormInterface): JobContribution
    {
        if (!this.jobContribution)
        {
            return new JobContribution(new JobContributionDto());
        }

        const jobContribution = new JobContribution(cloneDeep(this.jobContribution.dto));

        this.addJobContributionFieldValues(jobContribution, form.items);

        return jobContribution;
    }

    /**
     * Adds field values from form items to the passed job contribution
     *
     * @param jobContribution
     * @param items
     */

    private addJobContributionFieldValues(jobContribution: JobContribution, items: (FormFieldSetInterface | FormFieldInterface)[])
    {
        for (const item of items)
        {
            if (!('key' in item))
            {
                this.addJobContributionFieldValues(jobContribution, item.fields);
                continue;
            }

            switch (item.key)
            {
            case 'notes':
                jobContribution.notes = item.value as string;
                break;

            case 'attachment1':
                jobContribution.attachment1 = item.value as AttachmentInterface;
                break;
            }
        }
    }

    /**
     * Handles file download requests
     *
     * @param attachment
     */

    private onDownload(attachment: AttachmentInterface)
    {
        this.$serviceContainer.jobService.downloadAttachment(attachment);
    }
}