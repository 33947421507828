import { Component, Vue, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';

@Component
export default class AbstractView extends Vue
{
    protected defaultTitle = 'Vertical Plus Partner Management System';

    /**
     * Returns the title for this view
     */

    protected get title(): string
    {
        return this.defaultTitle;
    }

    @Watch('title')
    private onTitleChanged()
    {
        document.title = this.title;
    }

    protected created()
    {
        this.onTitleChanged();
    }

    protected beforeMount()
    {
        window.addEventListener('beforeunload', this.preventNavigation);
    }

    protected beforeDestroy()
    {
        window.removeEventListener('beforeunload', this.preventNavigation);
        this.$unlockPage();
    }

    protected beforeRouteLeave(
        to: Route, from: Route, next: NavigationGuardNext,
    )
    {
        if (this.isPageLocked && !window.confirm('Are you sure you want to leave this page? You will lose any unsaved data.'))
        {
            next(false);
        }
        else
        {
            this.$unlockPage();
            next();
        }
    }

    /**
     * Returns whether the page is locked
     */

    private get isPageLocked(): boolean
    {
        return this.$store.getters['ui/pageLocked'] as boolean;
    }

    /**
     * Prevents navigation away from the current page if it has been locked
     *
     * @param event
     */

    private preventNavigation(event: BeforeUnloadEvent)
    {
        if (!this.isPageLocked)
        {
            return;
        }

        event.preventDefault();
        event.returnValue = 'Are you sure you want to leave this page? You will lose any unsaved data.';
    }
}