import AbstractPostTask from '../abstract-post-task';
import Exception from '@vertical-plus/vue-js-api-platform/dist/model/exception/exception';

export default class RefreshTokenTask extends AbstractPostTask
{
    public async run(): Promise<void>
    {
        try
        {
            const response = await this.execute();

            if (response.status !== this.successCode)
            {
                throw new Error(Exception.RefreshTokenExpired);
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any)
        {
            switch (error.message)
            {
            case Exception.TokenExpired:
                throw new Error(Exception.RefreshTokenExpired);

            default:
                throw error;
            }
        }
    }

    protected get url(): string
    {
        return '/partners/refresh-token';
    }

    protected get successCode(): number
    {
        return 200;
    }
}