import './plugins/app';

// Disabled until we figure out how to deal with blank pages (old JS file deleted)
// Import './registerServiceWorker';

import '@vertical-plus/vue-js-components';
import '@vertical-plus/vue-js-micro-service-components';

import App from '@/app/index.vue';
import Attachment from './components/attachment/index.vue';
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import VueScrollTo from 'vue-scrollto';
import i18n from './i18n';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(VueApexCharts);
Vue.component('ApexChart', VueApexCharts);

Vue.use(VueScrollTo);

// Register components used within this project multiple times globally
Vue.component('app-attachment', Attachment);

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');