import AttachmentInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/attachment-interface';
import ClientUserDto from '../client/client-user-dto';
import JobDto from './job-dto';
import UserDto from '@vertical-plus/vue-js-micro-service-components/lib/model/entity/organisation/user-dto';

export default class JobContributionDto
{
    public id: number|null = null;
    public job: JobDto|null = null;
    public user: UserDto|null = null;
    public clientUser: ClientUserDto|null = null;
    public type: string|null = null;
    public timeSpent = 0;
    public amount = '0.00';
    public notes = '';
    public attachment1: AttachmentInterface|null = null;
    public clientVisible = false;
    public markdown = true;
    public deleted = false;
    public dateCreated = '';
}