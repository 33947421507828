import AbstractGetTask from '../abstract-get-task';
import Exception from '@vertical-plus/vue-js-api-platform/dist/model/exception/exception';
import JobCountsInterface from '@/model/vo/job/job-counts-interface';

export default class GetJobCountsTask extends AbstractGetTask
{
    public async run(): Promise<JobCountsInterface>
    {
        const response = await this.execute();

        if (response.status === this.successCode)
        {
            return response.data;
        }
        else
        {
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return '/partners/job-counts';
    }
}