import { cloneDeep } from 'lodash';
import { formatISO, parseISO } from 'date-fns';
import AttachmentInterface from '@vertical-plus/vue-js-components/lib/model/vos/form/attachment-interface';
import ClientUser from '../client/client-user';
import Decimal from 'big.js';
import Job from './job';
import JobContributionDto from './job-contribution-dto';
import User from '@vertical-plus/vue-js-micro-service-components/lib/model/entity/organisation/user';

export default class JobContribution
{
    public dto!: JobContributionDto;

    public constructor(dto: JobContributionDto)
    {
        this.dto = Object.assign({}, dto);

        this._jobId = this.dto.job ? this.dto.job.id : null;
        this._userId = this.dto.user ? this.dto.user.id : null;
        this._clientUserId = this.dto.clientUser ? this.dto.clientUser.id : null;
    }

    public get id(): number|null
    {
        return this.dto.id;
    }

    public set id(id: number|null)
    {
        this.dto.id = id;
    }

    public get job(): Job|null
    {
        if (!this.dto.job)
        {
            return null;
        }

        return new Job(this.dto.job);
    }

    private _jobId: number|null = null;

    public get jobId(): number|null
    {
        return this._jobId;
    }

    public set jobId(id: number|null)
    {
        this._jobId = id;
    }

    public get user(): User|null
    {
        if (!this.dto.user)
        {
            return null;
        }

        return new User(this.dto.user);
    }

    private _userId: string|null = null;

    public get userId(): string|null
    {
        return this._userId;
    }

    public set userId(id: string|null)
    {
        this._userId = id;
    }

    public get clientUser(): ClientUser|null
    {
        if (!this.dto.clientUser)
        {
            return null;
        }

        return new ClientUser(this.dto.clientUser);
    }

    private _clientUserId: number|null = null;

    public get clientUserId(): number|null
    {
        return this._clientUserId;
    }

    public set clientUserId(id: number|null)
    {
        this._clientUserId = id;
    }

    public get type(): string|null
    {
        return this.dto.type;
    }

    public set type(type: string|null)
    {
        this.dto.type = type;
    }

    public get timeSpent(): number
    {
        return this.dto.timeSpent;
    }

    public set timeSpent(timeSpent: number)
    {
        this.dto.timeSpent = timeSpent;
    }

    public get amount(): Decimal
    {
        return new Decimal(this.dto.amount);
    }

    public set amount(amount: Decimal)
    {
        this.dto.amount = amount.toString();
    }

    public get notes(): string
    {
        return this.dto.notes;
    }

    public set notes(notes: string)
    {
        this.dto.notes = notes;
    }

    public get hasAttachments(): boolean
    {
        return !!(this.attachment1);
    }

    public get attachment1(): AttachmentInterface|null
    {
        return this.dto.attachment1;
    }

    public set attachment1(attachment1: AttachmentInterface|null)
    {
        this.dto.attachment1 = attachment1;
    }

    public get clientVisible(): boolean
    {
        return this.dto.clientVisible;
    }

    public set clientVisible(clientVisible: boolean)
    {
        this.dto.clientVisible = clientVisible;
    }

    public get markdown(): boolean
    {
        return this.dto.markdown;
    }

    public set markdown(markdown: boolean)
    {
        this.dto.markdown = markdown;
    }

    public get deleted(): boolean
    {
        return this.dto.deleted;
    }

    public set deleted(deleted: boolean)
    {
        this.dto.deleted = deleted;
    }

    public get dateCreated(): Date
    {
        return parseISO(this.dto.dateCreated);
    }

    public set dateCreated(dateCreated: Date)
    {
        this.dto.dateCreated = formatISO(dateCreated);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get params(): {[key: string]: any}
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dto: any = cloneDeep(this.dto);

        dto.job = this.jobId ? `/jobs/${ this.jobId }` : null;
        dto.user = this.userId ? `/users/${ this.userId }` : null;
        dto.clientUser = this.clientUserId ? `/client_users/${ this.clientUserId }` : null;

        return dto;
    }
}