import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { formatISO } from 'date-fns';
import Job from '@/model/entity/job/job';
import JobContribution from '@/model/entity/job/job-contribution';
import JobContributionComponent from '../job-contribution/index.vue';

@Component({
    components:
    {
        'app-job-contribution': JobContributionComponent,
    },
})
export default class JobContributions extends Vue
{
    @Prop()
    private job!: Job;

    private jobId: number|null = null;

    private contributions: JobContribution[] = [];
    private loading = true;

    private showAddContribution = false;
    private newContribution: JobContribution|null = null;

    $refs!:
    {
        newContribution: HTMLElement
    };

    private created()
    {
        this.onJobUpdated();
    }

    @Watch('job')
    private onJobUpdated()
    {
        if (!this.job)
        {
            this.jobId = null;
            this.contributions = [];

            return;
        }

        if (this.job.id !== this.jobId)
        {
            this.jobId = this.job.id;
            this.loadJobContributions();
        }
    }

    /**
     * Returns whether the contributions should be in descending order
     */

    private get descending(): boolean
    {
        return this.$store.getters['ui/jobContributionsDescending'] as boolean;
    }

    /**
     * Set whether the contributions should be in descending order
     */

    private set descending(descending: boolean)
    {
        this.$store.commit('ui/jobContributionsDescending', descending);
    }

    /**
     * Returns the actual notes (minus timesheet entries)
     */

    private get notes(): JobContribution[]
    {
        const notes: JobContribution[] = [];

        for (const contribution of this.contributions)
        {
            if (contribution.notes === 'Contribution from timesheet')
            {
                continue;
            }

            notes.push(contribution);
        }

        // Handle the ordering in the client as all contributions are currently returned from the service request
        if (this.descending)
        {
            notes.reverse();
        }

        return notes;
    }

    /**
     * Loads the job contributions.
     */

    private async loadJobContributions()
    {
        if (!this.job.id)
        {
            return;
        }

        this.loading = true;

        try
        {
            const results = await this.$serviceContainer.jobService.findJobContributions({
                job: `${ this.job.id }`,
                itemsPerPage: 1000,
                order: [
                    {
                        key: 'dateCreated',
                        descending: false,
                    },
                ],
            });

            this.contributions = results.results;
        }
        catch (error)
        {
            console.error(error);
        }
        finally
        {
            this.loading = false;
        }
    }

    /**
     * Handles request to add a contribution.
     */

    private onAddContribution()
    {
        if (!this.$clientUser)
        {
            return;
        }

        this.newContribution = new JobContribution({
            id: null,
            job: this.job.dto,
            user: null,
            notes: '',
            attachment1: null,
            clientVisible: true,
            clientUser: this.$clientUser.dto,
            type: null,
            amount: '0.00',
            timeSpent: 0,
            markdown: true,
            deleted: false,
            dateCreated: formatISO(new Date()),
        });

        this.showAddContribution = true;

        const test = document.getElementById('job-preview-content');
        let container: string;
        if (test)
        {
            container = '#job-preview-content';
        }
        else
        {
            container = 'body';
        }

        window.setTimeout(() =>
        {
            this.$scrollTo(
                '#new-contribution', 500, { container },
            );
        }, 0);
    }

    /**
     * Emits when the contribution is docked
     */

    private onIsDocked()
    {
        this.$emit('isDocked');
    }

    /**
     * Handles saved new contribution
     */

    private onNewContributionSaved()
    {
        this.onCancelNewContribution();
        this.onContributionSaved();

        this.$emit('new-contribution');
    }

    /**
     * Handles the cancellation of the new contribution
     */

    private onCancelNewContribution()
    {
        this.showAddContribution = false;
        this.newContribution = null;
    }

    /**
     * Handles saved contributions
     */

    private onContributionSaved()
    {
        this.loadJobContributions();
    }
}