import AbstractService from '@vertical-plus/vue-js-micro-service-components/lib/model/service/abstract-service';
import ClientUser from '@/model/entity/client/client-user';
import CoreException from '@vertical-plus/vue-js-api-platform/dist/model/exception/exception';
import CredentialsVo from '@/model/vo/authentication/credentials-vo';
import Event from '@vertical-plus/vue-js-micro-service-components/lib/model/event/event';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import Exception from '@vertical-plus/vue-js-micro-service-components/lib/model/exception/exception';
import GetClientUserTask from '@/model/task/client/get-client-user-task';
import LoginTask from '@/model/task/authentication/login-task';
import RefreshTokenTask from '@/model/task/authentication/refresh-token';
import ResetPasswordTask from '@/model/task/authentication/reset-password';
import Util from '@vertical-plus/vue-js-core/dist/util/util';

export default class AuthenticationService extends AbstractService
{
    /**
     * Attempts login, returning the logged in user if successful
     *
     * @param username
     * @param password
     * @returns
     */

    public async login(username: string, password: string): Promise<ClientUser>
    {
        // Attempt to login with the passed details
        const task = new LoginTask();
        task.username = username;
        task.password = password;

        const credentials = await task.run();
        if (!credentials)
        {
            throw new Error(Exception.UserNotFound);
        }

        // Update credentials
        await this.updateCredentials(credentials);

        // Update and return the current user
        return this.updateUser(credentials.id);
    }

    /**
     * Logs the current user out
     */

    public async logout(): Promise<void>
    {
        this.store.commit('authentication/token', null);
        this.store.commit('authentication/refreshingToken', false);
        this.store.commit('app/clientUser', null);
    }

    /**
     * Refreshes the authentication token
     */

    public async refreshToken(): Promise<void>
    {
        try
        {
            // Check for other running refresh token requests
            if (this.store.getters['authentication/refreshingToken'])
            {
                // Wait until the running request has finished
                while (this.store.getters['authentication/refreshingToken'])
                {
                    await Util.sleep(50);
                }

                // Simply return out as the previous request will either have been successful or would have logged the user out
                return;
            }

            // Mark that we are refreshing the token to prevent duplicate requests
            this.store.commit('authentication/refreshingToken', true);

            // Attempt to renew the token using the refresh token
            const task = new RefreshTokenTask();
            task.token = this.token;

            // Update credentials
            await task.run();

            // Mark as no longer refreshing the token
            this.store.commit('authentication/refreshingToken', false);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any)
        {
            switch (error.message)
            {
            case CoreException.RefreshTokenExpired:
                // Send a message to indicate the session has expired
                EventBus.instance.$emit(Event.SessionExpired);

                // Log the user out
                this.logout();

                // Mark as no longer refreshing the token
                this.store.commit('authentication/refreshingToken', false);

                break;

            default:
                // Mark as no longer refreshing the token
                this.store.commit('authentication/refreshingToken', false);

                throw error;
            }
        }
    }

    /**
     * Resets the password for a user
     *
     * @param email
     */

    public async resetPassword(email: string): Promise<void>
    {
        const task = new ResetPasswordTask();
        task.email = email;

        await task.run();
    }

    /**
     * Updates the credentials in the session
     *
     * @param credentials
     */

    private async updateCredentials(credentials: CredentialsVo)
    {
        this.store.commit('authentication/token', credentials.token);
        this.store.commit('authentication/refreshingToken', false);
    }

    /**
     * Updates the current user
     *
     * @param id
     * @returns
     */

    private async updateUser(id: number): Promise<ClientUser>
    {
        // Load the details of the logged in user
        const userTask = new GetClientUserTask();
        userTask.token = this.token;
        userTask.id = id;

        const user = await userTask.run();
        if (!user)
        {
            throw new Error(Exception.UserNotFound);
        }

        // Store the user
        this.store.commit('app/clientUser', user);

        // Return the logged in user
        return user;
    }
}