import ForgottenPasswordView from '@/views/forgotten-password/index.vue';
import HomeView from '@/views/home/index.vue';
import JobCreateView from '@/views/job/create/index.vue';
import JobEditView from '@/views/job/edit/index.vue';
import JobIndexView from '@/views/job/index/index.vue';
import JobView from '@/views/job/view/index.vue';
import ProfileView from '@/views/profile/index.vue';

const routes =
[
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta:
        {
            requiresAuth: false,
            noUser: true,
        },
    },
    {
        path: '/jobs/new',
        name: 'job-create',
        component: JobCreateView,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/jobs/:type',
        name: 'job-index',
        component: JobIndexView,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/job/:id',
        name: 'job-view',
        component: JobView,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/job/:id/edit',
        name: 'job-edit',
        component: JobEditView,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/account',
        name: 'profile',
        component: ProfileView,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/forgotten-password',
        name: 'forgotten-password',
        component: ForgottenPasswordView,
        meta:
        {
            requiresAuth: false,
            noUser: true,
        },
    },
];

export default routes;