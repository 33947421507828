import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';

@Component
export default class HeaderComponent extends Vue
{
    private username = '';
    private password = '';
    private showPassword = false;

    /**
     * Returns the logo path for the header
     */

    private get logo(): string
    {
        return require('@/assets/images/logos/vp-logo-full_white.svg');
    }

    /**
     * Returns the page title for the header
     */

    private get pageTitle(): string
    {
        return this.$vuetify.breakpoint.mdAndUp ? this.$t('general.message.app_title').toString() : '';
    }

    /**
     * Toggles visibility of the navbar
     */

    private onToggleNavbar()
    {
        this.$store.commit('ui/navbarVisible', !this.$store.getters['ui/navbarVisible']);
    }

    /**
     * Handles login
     */

    private async onLogin()
    {
        try
        {
            const user = await this.$serviceContainer.authenticationService.login(this.username, this.password);
            if (!user)
            {
                return;
            }

            // Reset the username and password
            this.username = '';
            this.password = '';

            // If a redirect has been set, navigate to that page
            const redirect = this.$store.getters['authentication/loginRedirectUrl'];
            if (redirect)
            {
                // Clear the redirect url
                this.$store.commit('authentication/loginRedirectUrl', null);

                // Redirect to the url
                this.$router.push(redirect);
            }

            // Otherwise redirect to the jobs page
            else
            {
                this.$router.replace({
                    name: 'job-index',
                    params: { type: 'current' },
                });
            }

            // Inform the user they are logged in
            EventBus.instance.$emit(
                'notification', this.$t('general.message.welcome_back_name', { name: user.firstName }), 'success',
            );
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any)
        {
        // Reset the password
            this.password = '';

            // Redirect to the forgotten password page
            this.$router.replace({ name: 'forgotten-password' });

            // Inform the user of the login error
            if (error.message === 'invalid_login_details')
            {
                EventBus.instance.$emit(
                    'notification', this.$t('authentication.error.login_failed_invalid_details'), 'error',
                );
            }
            else
            {
                EventBus.instance.$emit(
                    'notification', this.$t('authentication.error.login_failed_server_error'), 'error',
                );
            }
        }
    }

    /**
     * Handles logout
     */

    private onLogout()
    {
        this.$serviceContainer.authenticationService.logout();
        this.$router.replace({ name: 'home' });
    }

    /**
     * Handles profile clicks
     */

    private onProfileClicked()
    {
        this.$router.push({
            name: 'profile',
        });
    }
}