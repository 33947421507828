import AbstractPatchTask from '../abstract-patch-task';
import Job from '@/model/entity/job/job';

export default class CloseJobTask extends AbstractPatchTask
{
    public jobId!: number;

    public async run(): Promise<Job|null|void>
    {
        const response = await this.execute();

        if (response.status === this.successCode)
        {
            return new Job(response.data);
        }

        this.handleErrorResponse(response.status);
    }

    protected get url(): string
    {
        return `/jobs/${ this.jobId }/close`;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}
    {
        return {
            id: this.jobId,
        };
    }
}